import { trigger, state, style, transition, animate } from '@angular/animations';
import { HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent {
  langs: string[] = [];
  isScrolled : string;
  title = 'plataformaAlcaldia';
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.querySelector('.header');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.remove('navbar-inverse2');
      element.classList.add('navbar-inverse');
      this.isScrolled = 'visible';
      VisibilityState.Visible
    } else {
      element.classList.remove('navbar-inverse');
      element.classList.add('navbar-inverse2');
      this.isScrolled = 'hidden';
      VisibilityState.Hidden
    }
  }
  constructor(private translate:TranslateService){
    // Traducir en el template
    this.translate.setDefaultLang('Es');
    this.translate.use('Es');

    this.translate.addLangs(['Es','En'])
  /*   const browserLang = this.translate.getBrowserLang()
    console.log('Browser Lang',browserLang);
    this.translate.use(browserLang.match(/Es|Es/) ? browserLang : 'Es' )  */
    
    // translate.
    //Traducir en el component
 /*    this.translate.get('hello').subscribe((res:string)=>{
      console.log(res);
    }) */
  }

}

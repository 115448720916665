import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegisterComponent } from 'src/app/home/register/register.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,
              private router: Router) { }

  ngOnInit(): void {
  }

  openModalRegister() {
    this.modalRef = this.modalService.show(RegisterComponent,{backdrop: 'static', class: 'modal-lg'});
  }

  scroll(id){
    this.router.navigate(['/'], { fragment: id });
    setTimeout(()=>{
      const element = document.getElementById(id);
      const yOffset = -120; 
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    },100)
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB9oEbkZR3hxID4O3NsZyuEuwoTc4NE_GM',
    authDomain: 'plataformaturistica-b9ab7.firebaseapp.com',
    databaseURL: 'https://plataformaturistica-b9ab7.firebaseio.com',
    projectId: 'plataformaturistica-b9ab7',
    storageBucket: 'plataformaturistica-b9ab7.appspot.com',
    messagingSenderId: '894175885057',
    appId: '1:894175885057:web:b28d4ddca9cd8b92d9cd00',
    measurementId: 'G-T1MHF2EJ2N',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<div class="footer">
    <!-- <div class="social_icons">
        <label class="m-auto">Siguenos en redes sociales: </label>
        <fa-icon class="m-auto" [icon]="faFacebook" size="lg" [styles]="{'stroke': 'black', 'color': 'black'}"></fa-icon>
        <fa-icon class="m-auto" [icon]="faInstagram" size="lg" [styles]="{'stroke': 'black', 'color': 'black'}"></fa-icon>
        <fa-icon class="m-auto" [icon]="faTwitter" size="lg" [styles]="{'stroke': 'black', 'color': 'black'}"></fa-icon>
    </div> -->
    <div class="container-fluid pt-3 pl-lg-5 pr-lg-5">
        <div class="row">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center bg-white">
                            <img width="100%" src="assets/images/patrocinadores-estrategias-sector-turismo.png" alt="">
                        </div>
                        <p>{{'municipal' | translate}} - Calle 9 No. 2-59, Ibagué - Tolima (Col) <br>
                            {{'phone_number' | translate}}: (+57 60) 2611182 - (+57 60) 2611686 - (+57 60) 2611855
                          (+57 60) 2613676 - (+57 60) 2611337 <br>
                          {{'atention' | translate}}: (+57 60) 2619099.</p>

                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row">
                    <div class="col-md-4 tour_footer">
                        <h4><strong>{{'touristic_offer' | translate}}</strong></h4>
                        <ul class="p-0">
                            <li class="pointer_click">
                                <a (click)="scroll('ofertas')">{{'Alojamientos' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('ofertas')">{{'Gastronomía' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('ofertas')">{{'Agencia de viajes' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('ofertas')">{{'Transporte especial de turismo' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('ofertas')">{{'Organizadores de eventos' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('ofertas')">{{'Guías e informadores de turismo' | translate}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <ul class="p-0">
                            <h4><strong>{{'routes' | translate}}</strong></h4>
                            <li class="pointer_click">
                                <a (click)="scroll('rutas')">{{'routes' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('zonas')">{{'tourism_section.zone_dna' | translate}}</a>
                            </li>
                            <li class="pointer_click">
                                <a (click)="scroll('bicirutas')">{{'bikes' | translate}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5">
                        <h4 class="pointer_click"><strong><a (click)="scroll('contacto')">{{'contact' | translate}}</a></strong></h4>
                        <ul class="p-0">
                          <li class="pointer_click">
                            <a href="https://www.facebook.com/DesarrolloEconomicoIbague" target="_blank"><img src="assets/images/icons8-facebook.svg" alt=""> Alcaldía de Ibagué</a>
                          </li>
                          <li class="pointer_click">
                            <a href="https://www.facebook.com/DesarrolloEconomicoIbague" target="_blank"><img src="assets/images/icons8-facebook.svg" alt=""> Secretaría Desarrollo Económico Ibagué</a>
                          </li>
                          <li class="pointer_click">
                            <a href="https://www.instagram.com/alcaldiadeibague/" target="_blank"><img src="assets/images/icons8-instagram.svg" alt=""> Alcaldía de Ibagué</a>
                          </li>
                          <li class="pointer_click">
                            <a href="https://twitter.com/Alcaldiaibague?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank"><img src="assets/images/icons8-twitter.svg" alt="" > Alcaldía de Ibagué</a>
                          </li>
                      </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="d-flex p-0 text-center mb-3">
              <li class="">
                  <p class="mr-2 mb-0">{{'core.tourist_provider' | translate}}</p>
              </li>
              <li class="">
                  <a class="register_menu" (click)="openModalRegister()">{{'core.register' | translate}}</a>
              </li>
          </ul>
          </div>
        </div>
    </div>
</div>

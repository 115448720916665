import {
  trigger,
  transition,
  animate,
  keyframes,
  style,
} from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  faBuilding,
  faBus,
  faCalendar,
  faCamera,
  faHiking,
  faHome,
  faInfo,
  faMap,
  faRoute,
  faSuitcase,
  faUserNinja,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AlcaldiaValidators } from 'src/app/utils/custom-validators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class RegisterComponent implements OnInit {
  buttonTitle = 'Enviar y Registrar';
  isLoading = false;
  faInfo = faInfo;
  categoryForm: FormGroup;
  registerStep1Form: FormGroup;
  // Formularios de alojamientos
  registerStep2Form: FormGroup;
  registerStep3Form: FormGroup;
  // Formularios de gastronomias
  registerStep2FormFood: FormGroup;

  // Agencias
  registerStep1FormTour: FormGroup;
  registerStep2FormTour: FormGroup;
  registerStep3FormTour: FormGroup;

  // Transporte
  registerStep1FormTransport: FormGroup;
  registerStep2FormTransport: FormGroup;
  registerStep3FormTransport: FormGroup;

  // Guias eventos
  registerStep2FormEvents: FormGroup;
  registerStep3FormEvents: FormGroup;

  // Guias turisticos
  registerStep1FormGuide: FormGroup;
  registerStep2FormGuide: FormGroup;
  registerStep3FormGuide: FormGroup;

  // Atractivos turisticos
  registerStep2FormAtractive: FormGroup;

  @Input()
  required: Boolean;
  categories = [
    {
      id: 1,
      icon: faBuilding,
      name: 'Alojamientos',
    },
    {
      id: 2,
      icon: faUtensils,
      name: 'Gastronomía',
    },
    {
      id: 3,
      icon: faRoute,
      name: 'Agencia de viajes',
    },
    {
      id: 4,
      icon: faBus,
      name: 'Transporte especial de turismo',
    },
    {
      id: 5,
      icon: faHiking,
      name: 'Guías e informadores de turismo',
    },
    {
      id: 6,
      icon: faCalendar,
      name: 'Organizadores de eventos',
    },
    {
      id: 7,
      icon: faCamera,
      name: 'Atractivos turísticos',
    },
    // {
    //   id: 6,
    //   icon: faUserNinja,
    //   name: 'Guías de turismo',
    // },
  ];

  ubications = [
    {
      id: 1,
      icon: faMap,
      name: 'Corredor Norte',
    },
    {
      id: 2,
      icon: faMap,
      name: 'Corredor Totumo Martinica Coello Cococora',
    },
    {
      id: 3,
      icon: faMap,
      name: 'Corredor Cañon del Combeima',
    },
    {
      id: 4,
      icon: faMap,
      name: 'Corredor Centro Zona ADN',
    },
    {
      id: 5,
      icon: faMap,
      name: 'Otro',
    },
  ]

  subcategories = [];

  logo: any;
  foto1: any;
  foto2: any;
  foto3: any;
  imagenes: any[] = [];
  urls: any[] = [];
  porcentaje = 0;
  finalizado = false;
  categoryName: string;
  subCategoryName: string;
  modelo: any;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private firestoreService: FirestoreService
  ) {
    this.categoryForm = this.formBuilder.group({
      category: [null, Validators.required],
      subCategory: [null, Validators.required],
      ubication: [null, Validators.required],
    });

    this.registerStep1Form = this.formBuilder.group({
      nameCompany: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      optionRnt: ['si', Validators.required],
      rnt: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.minLength(5),
          AlcaldiaValidators.maxLength(9),
          AlcaldiaValidators.numerico
        ],
      ],
      optionZone: [null, Validators.required],
      addressCompany: [
        null,
        [Validators.required, AlcaldiaValidators.direccion],
      ],
      locationEstablishment: [
        null,
        [Validators.required, Validators.minLength(5),],
      ],
      optionLegal: [null, Validators.required],
      addressCorrespondence: [null, Validators.required],
      idPerson: [
        null,
        [Validators.required, AlcaldiaValidators.minLength(9),
        AlcaldiaValidators.maxLength(11), , AlcaldiaValidators.numerico],
      ],
      namePerson: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      cellphone: [
        null,
        [Validators.required, AlcaldiaValidators.minLength(7), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      email: [null, [Validators.required, Validators.email]],
      cellphoneCompany: [
        null,
        [Validators.required, AlcaldiaValidators.minLength(7), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      access: [null, Validators.required],
      day1: [null],
      day2: [null],
      day3: [null],
      day4: [null],
      day5: [null],
      time1Week: [null],
      time2Week: [null],
      day6: [null],
      day7: [null],
      day8: [null],
      time1Weekend: [null],
      time2Weekend: [null],
      employedDirect: [null, [Validators.required, AlcaldiaValidators.numerico]],
      employedTemp: [null, [Validators.required, AlcaldiaValidators.numerico]],
    });

    this.registerStep2Form = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],
      numberRoomsCompany: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberBedRoomsCompany: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      simpleBedroom: ['si', Validators.required],
      numberSimpleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceSimpleBedroom1: [null, Validators.required],
      priceSimpleBedroom2: [null, Validators.required],
      priceSimpleBedroom3: [null, Validators.required],
      doubleBedroom: [null, Validators.required],
      numberDoubleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceDoubleBedroom1: [null, Validators.required],
      priceDoubleBedroom2: [null, Validators.required],
      priceDoubleBedroom3: [null, Validators.required],
      tripleBedroom: [null, Validators.required],
      numberTripleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceTripleBedroom1: [null, Validators.required],
      priceTripleBedroom2: [null, Validators.required],
      priceTripleBedroom3: [null, Validators.required],
      quadrupleBedroom: [null, Validators.required],
      numberQuadrupleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceQuadrupleBedroom1: [null, Validators.required],
      priceQuadrupleBedroom2: [null, Validators.required],
      priceQuadrupleBedroom3: [null, Validators.required],
      suiteBedroom: [null, Validators.required],
      numberSuiteBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceSuiteBedroom1: [null, Validators.required],
      priceSuiteBedroom2: [null, Validators.required],
      priceSuiteBedroom3: [null, Validators.required],
      presidentialBedroom: [null, Validators.required],
      numberPresidentialBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      pricePresidentialBedroom1: [null, Validators.required],
      pricePresidentialBedroom2: [null, Validators.required],
      pricePresidentialBedroom3: [null, Validators.required],
      otherBedroom: [null],
      eventsCompany: [null, Validators.required],
      numberEventRooms: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      capacityEventRooms: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceEventRooms: [null, Validators.required],
      otherEventRooms: [null],
      audiovisualAids: [null],
      sound: [null],
      microphone: [null],
      waterCoffee: [null],
      Catering: [null],
      otherEventService: [null],
      restaurante: [null],
      piscina: [null],
      aire: [null],
      television: [null],
      internet: [null],
      banos: [null],
      salon: [null],
      parqueadero: [null],
      otherServiceCompany: [null],
    });

    this.registerStep2Form = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],
      numberRoomsCompany: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      numberBedRoomsCompany: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      simpleBedroom: ['si', Validators.required],
      numberSimpleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceSimpleBedroom1: [null, Validators.required],
      priceSimpleBedroom2: [null, Validators.required],
      priceSimpleBedroom3: [null, Validators.required],
      doubleBedroom: [null, Validators.required],
      numberDoubleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceDoubleBedroom1: [null, Validators.required],
      priceDoubleBedroom2: [null, Validators.required],
      priceDoubleBedroom3: [null, Validators.required],
      tripleBedroom: [null, Validators.required],
      numberTripleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceTripleBedroom1: [null, Validators.required],
      priceTripleBedroom2: [null, Validators.required],
      priceTripleBedroom3: [null, Validators.required],
      quadrupleBedroom: [null, Validators.required],
      numberQuadrupleBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceQuadrupleBedroom1: [null, Validators.required],
      priceQuadrupleBedroom2: [null, Validators.required],
      priceQuadrupleBedroom3: [null, Validators.required],
      suiteBedroom: [null, Validators.required],
      numberSuiteBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceSuiteBedroom1: [null, Validators.required],
      priceSuiteBedroom2: [null, Validators.required],
      priceSuiteBedroom3: [null, Validators.required],
      presidentialBedroom: [null, Validators.required],
      numberPresidentialBedroom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      pricePresidentialBedroom1: [null, Validators.required],
      pricePresidentialBedroom2: [null, Validators.required],
      pricePresidentialBedroom3: [null, Validators.required],
      otherBedroom: [null],
      eventsCompany: [null, Validators.required],
      numberEventRooms: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      capacityEventRooms: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999)],
      ],
      priceEventRooms: [null, Validators.required],
      otherEventRooms: [null],
      audiovisualAids: [null, Validators.required],
      sound: [null, Validators.required],
      microphone: [null, Validators.required],
      waterCoffee: [null, Validators.required],
      Catering: [null, Validators.required],
      otherEventService: [null],
      restaurante: [null],
      piscina: [null],
      aire: [null],
      television: [null],
      internet: [null],
      banos: [null],
      salon: [null],
      parqueadero: [null],
      otherServiceCompany: [null],
    });

    this.registerStep3Form = this.formBuilder.group({
      webSite: [null, Validators.required],
      urlWebSite: [null, Validators.required],
      payWebSite: [null, Validators.required],
      bookWebSite: [null, Validators.required],
      facebook: [null],
      twitter: [null],
      instagram: [null],
      otherSocialMedia: [null],
      marketing: [null, Validators.required],
      tripadvisor: [null],
      airbnb: [null],
      booking: [null],
      tolimaTravel: [null],
      atrapalo: [null],
      recomendations: [null, Validators.required],
      guild: [null, Validators.required],
    });

    // Form Food
    this.registerStep2FormFood = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],
      numberTableCompany: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberTable2: [null, [Validators.required, AlcaldiaValidators.numerico]],
      numberTable4: [null, [Validators.required, AlcaldiaValidators.numerico]],
      numberTable6: [null, [Validators.required, AlcaldiaValidators.numerico]],
      numberTableOther: [null, AlcaldiaValidators.numerico],
      restaurantArea: [null, Validators.required],
      picnicZone: [
        null,
        [Validators.required],
      ],
      numberPicnicZone: [null, [Validators.required, AlcaldiaValidators.numerico]],
      menu: [null, Validators.required],
      eventsCompany: [null, Validators.required],
      numberEventRooms: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      capacityEventRooms: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceEventRooms: [null, Validators.required],
      otherEventRooms: [null],
      audiovisualAids: [null, Validators.required],
      sound: [null, Validators.required],
      microphone: [null, Validators.required],
      waterCoffee: [null, Validators.required],
      Catering: [null, Validators.required],
      otherEventService: [null],
      restaurante: [null],
      piscina: [null],
      aire: [null],
      television: [null],
      internet: [null],
      banos: [null],
      salon: [null],
      parqueadero: [null],
      otherServiceCompany: [null],
    });

    // Form Tour

    this.registerStep1FormTour = this.formBuilder.group({
      nameCompany: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      optionRnt: ['si', Validators.required],
      rnt: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.minLength(5),
          AlcaldiaValidators.maxLength(9),
        ],
      ],
      optionZone: [null, Validators.required],
      addressCompany: [
        null,
        [Validators.required, AlcaldiaValidators.direccion],
      ],
      locationEstablishment: [
        null,
        [Validators.required, Validators.minLength(5),],
      ],
      digitalAgent: [null, Validators.required],
      addressCorrespondence: [null, Validators.required],
      idPerson: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      namePerson: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      cellphone: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      email: [
        null,
        [Validators.required, Validators.email],
      ],
      cellphoneCompany: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      access: [null, Validators.required],
      day1: [null],
      day2: [null],
      day3: [null],
      day4: [null],
      day5: [null],
      time1Week: [null],
      time2Week: [null],
      day6: [null],
      day7: [null],
      day8: [null],
      time1Weekend: [null],
      time2Weekend: [null],
      employedDirect: [
        null,
        [Validators.required, AlcaldiaValidators.numerico],
      ],
      employedTemp: [null, [Validators.required, AlcaldiaValidators.numerico]],
    });

    this.registerStep2FormTour = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],
      internationalEmissive: [null, Validators.required],
      nationalEmissive: [null, Validators.required],
      tolimaInternationalEmissive: [null, Validators.required],
      tolimaNationalEmissive: [null, Validators.required],
      departmentTour: [null, Validators.required],
      otherTour: [null],
      mountain: [null],
      canyoning: [null],
      rafting: [null],
      rappel: [null],
      canopy: [null],
      trekking: [null],
      speleology: [null],
      ride: [null],
      mountainBiking: [null],
      paragliding: [null],
      certificate: [null, Validators.required],
      certificateRafting: [null],
      certificateRapel: [null],
      certificateSpeleology: [null],
      certificateParagliding: [null],
      certificateRide: [null],
      certificateCanyoning: [null],
      otherCertificate: [null],
      transport: [null, Validators.required],
      numberCar: [null, [Validators.required, AlcaldiaValidators.numerico]],
      regionalService: [null, Validators.required],
      nationalService: [null, Validators.required],
    });

    // Transport Forms
    this.registerStep1FormTransport = this.formBuilder.group({
      nameCompany: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      optionRnt: ['si', Validators.required],
      rnt: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.minLength(5),
          AlcaldiaValidators.maxLength(9),
        ],
      ],
      optionZone: [null, Validators.required],
      addressCompany: [
        null,
        [Validators.required, AlcaldiaValidators.direccion],
      ],
      locationEstablishment: [
        null,
        [Validators.required, Validators.minLength(5),],
      ],
      digitalAgent: [null, Validators.required],
      addressCorrespondence: [null, Validators.required],
      idPerson: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      namePerson: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      cellphone: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      email: [
        null,
        [Validators.required, Validators.email],
      ],
      cellphoneCompany: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      access: [null, Validators.required],
      day1: [null],
      day2: [null],
      day3: [null],
      day4: [null],
      day5: [null],
      time1Week: [null],
      time2Week: [null],
      day6: [null],
      day7: [null],
      day8: [null],
      time1Weekend: [null],
      time2Weekend: [null],
      employedDirect: [
        null,
        [Validators.required, AlcaldiaValidators.numerico],
      ],
      employedTemp: [null, [Validators.required, AlcaldiaValidators.numerico]],
    });

    this.registerStep2FormTransport = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],

      certificate: [null, Validators.required],
      certificateQuality: [null],
      certificateISOption: [null, Validators.required],
      certificateISO: [null],
      certificateBIOption: [null, Validators.required],
      certificateBIO: [null],
      otherCertificate: [null],
      transportBUS: [null, Validators.required],
      numberBUS: [null, [Validators.required, AlcaldiaValidators.numerico]],
      transportVANS: [null, Validators.required],
      numberVANS: [null, [Validators.required, AlcaldiaValidators.numerico]],
      transportCHIVAS: [null, Validators.required],
      numberCHIVAS: [null, [Validators.required, AlcaldiaValidators.numerico]],
      otherTransport: [null],
    });

    this.registerStep1FormGuide = this.formBuilder.group({
      languages: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      certificateLanguagesOption: ['si', Validators.required],
      certificateLanguages: [
        null,
        [Validators.required, AlcaldiaValidators.alfabetico],
      ],
      optionRnt: ['si', Validators.required],
      rnt: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.minLength(5),
          AlcaldiaValidators.maxLength(9),
        ],
      ],
      optionZone: [null, Validators.required],
      addressCompany: [
        null,
        [Validators.required, AlcaldiaValidators.direccion],
      ],
      optionNumberCarpro: [null, Validators.required],
      numberCardPro: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.minLength(5),
          AlcaldiaValidators.maxLength(15),
        ],
      ],
      addressCorrespondence: [null, Validators.required],
      idPerson: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      namePerson: [null, [Validators.required, AlcaldiaValidators.alfabetico]],
      cellphone: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      email: [
        null,
        [Validators.required, Validators.email],
      ],
      cellphoneCompany: [
        null,
        [
          Validators.required,
          AlcaldiaValidators.numerico,
          AlcaldiaValidators.minLength(7),
          AlcaldiaValidators.maxLength(12),
        ],
      ],
      descriptionService: [null, Validators.required],
    });

    this.registerStep2FormGuide = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],
      certificate: [null, Validators.required],
      certificatesList: [null],
    });

    // Events form

    this.registerStep2FormEvents = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],

      certificate: [null, Validators.required],
      certificateQuality: [null],
      certificateISOption: [null, Validators.required],
      certificateISO: [null],
      certificateBIOption: [null, Validators.required],
      certificateBIO: [null],
      otherCertificate: [null],
      audit: ['si', Validators.required],
      numberAudit: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberAfor: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceAudit: [null, Validators.required],
      room: ['si', Validators.required],
      numberRoom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberAforRoom: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceRoom: [null, Validators.required],
      roomU: ['si', Validators.required],
      numberRoomU: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberAforRoomU: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceRoomU: [null, Validators.required],
      imperial: ['si', Validators.required],
      numberImperial: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberAforImperial: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceImperial: [null, Validators.required],
      coctel: ['si', Validators.required],
      numberCoctel: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      numberAforCoctel: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99999999999), AlcaldiaValidators.numerico],
      ],
      priceCoctel: [null, Validators.required],
      otherRoom: [null],

    });

    // Atractive form

    this.registerStep2FormAtractive = this.formBuilder.group({
      priceAVGCompany: [null, Validators.required],

      certificate: [null, Validators.required],
      certificateQuality: [null],
      certificateISOption: [null, Validators.required],
      certificateISO: [null],
      certificateBIOption: [null, Validators.required],
      certificateBIO: [null],
      otherCertificate: [null],
    });

  }

  pun() {
    let date = new Date(this.registerStep1FormTransport.get('time1Week').value);
  }
  ngOnInit(): void {
    this.category.valueChanges.subscribe((value) => {
      this.subcategories = [
        {
          category: 1,
          id: 1,
          icon: faBuilding,
          name: 'Hotel',
        },
        {
          category: 1,
          id: 2,
          icon: faBuilding,
          name: 'Hostal',
        },
        {
          category: 1,
          id: 3,
          icon: faBuilding,
          name: 'Camping',
        },
        {
          category: 1,
          id: 4,
          icon: faBuilding,
          name: 'Finca turística',
        },
        {
          category: 1,
          id: 5,
          icon: faBuilding,
          name: 'ApartaHotel',
        },
        {
          category: 1,
          id: 6,
          icon: faBuilding,
          name: 'Centro vacacional',
        },
        {
          category: 2,
          id: 1,
          icon: faUtensils,
          name: 'Restaurante',
        },
        {
          category: 2,
          id: 2,
          icon: faUtensils,
          name: 'Restaurante - Bar',
        },
        {
          category: 2,
          id: 3,
          icon: faUtensils,
          name: 'Establecimiento de Comidas rápidas',
        },
        {
          category: 2,
          id: 4,
          icon: faUtensils,
          name: 'Establecimiento de Comidas Típica',
        },
        {
          category: 2,
          id: 5,
          icon: faUtensils,
          name: 'Café',
        },
        {
          category: 2,
          id: 6,
          icon: faUtensils,
          name: 'Café Bar',
        },
        {
          category: 2,
          id: 7,
          icon: faUtensils,
          name: 'Cafetería',
        },
        {
          category: 2,
          id: 8,
          icon: faUtensils,
          name: 'Heladería',
        },
        {
          category: 2,
          id: 9,
          icon: faUtensils,
          name: 'Comidas rápidas',
        },
        {
          category: 2,
          id: 9,
          icon: faUtensils,
          name: 'Asadero',
        },
        {
          category: 2,
          id: 10,
          icon: faUtensils,
          name: 'Asadero',
        },
        {
          category: 3,
          id: 1,
          icon: faRoute,
          name: 'Agencia de viajes y turismo',
        },
        {
          category: 3,
          id: 2,
          icon: faRoute,
          name: 'Agencia de viajes operadora',
        },
        {
          category: 3,
          id: 3,
          icon: faRoute,
          name: 'Agencia de viajes mayorista',
        },
        {
          category: 4,
          id: 1,
          icon: faBus,
          name: 'Transporte terrestre automotor especial',
        },
        {
          category: 4,
          id: 2,
          icon: faBus,
          name: 'Operador de chivas',
        },
        {
          category: 5,
          id: 1,
          icon: faHiking,
          name: 'Guia profesional',
        },
        {
          category: 5,
          id: 2,
          icon: faHiking,
          name: 'Informador de turismo',
        },
        {
          category: 5,
          id: 3,
          icon: faHiking,
          name: 'Tecnología en guianza turística',
        },
        {
          category: 6,
          id: 1,
          icon: faCalendar,
          name: 'Operador profesional de congresos y eventos',
        },
        {
          category: 6,
          id: 2,
          icon: faCalendar,
          name: 'Casa de eventos',
        },
        {
          category: 6,
          id: 3,
          icon: faCalendar,
          name: 'Salón de eventos',
        },
        {
          category: 7,
          id: 1,
          icon: faCamera,
          name: 'Atractivo natural',
        },
        {
          category: 7,
          id: 2,
          icon: faCamera,
          name: 'Atractivo cultural',
        },
        {
          category: 7,
          id: 3,
          icon: faCamera,
          name: 'Otro',
        },
      ];
      this.subcategories = this.subcategories.filter(
        (subcategory) => subcategory.category === value
      );

      this.categoryName = this.categories.find((x) => x.id === value).name;
    });

    this.subCategory.valueChanges.subscribe((value) => {
      if (value) {
        this.subCategoryName = this.subcategories.find(
          (x) => x.id === value
        ).name;
      }
    });

    this.optionRnt.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.rnt.disable();
      } else {
        this.rnt.enable();
      }
    });

    this.simpleBedroom.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberSimpleBedroom.disable();
        this.priceSimpleBedroom1.disable();
        this.priceSimpleBedroom2.disable();
        this.priceSimpleBedroom3.disable();
      } else {
        this.numberSimpleBedroom.enable();
        this.priceSimpleBedroom1.enable();
        this.priceSimpleBedroom2.enable();
        this.priceSimpleBedroom3.enable();
      }
    });

    this.doubleBedroom.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberDoubleBedroom.disable();
        this.priceDoubleBedroom1.disable();
        this.priceDoubleBedroom2.disable();
        this.priceDoubleBedroom3.disable();
      } else {
        this.numberDoubleBedroom.enable();
        this.priceDoubleBedroom1.enable();
        this.priceDoubleBedroom2.enable();
        this.priceDoubleBedroom3.enable();
      }
    });

    this.tripleBedroom.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberTripleBedroom.disable();
        this.priceTripleBedroom1.disable();
        this.priceTripleBedroom2.disable();
        this.priceTripleBedroom3.disable();
      } else {
        this.numberTripleBedroom.enable();
        this.priceTripleBedroom1.enable();
        this.priceTripleBedroom2.enable();
        this.priceTripleBedroom3.enable();
      }
    });

    this.quadrupleBedroom.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberQuadrupleBedroom.disable();
        this.priceQuadrupleBedroom1.disable();
        this.priceQuadrupleBedroom2.disable();
        this.priceQuadrupleBedroom3.disable();
      } else {
        this.numberQuadrupleBedroom.enable();
        this.priceQuadrupleBedroom1.enable();
        this.priceQuadrupleBedroom2.enable();
        this.priceQuadrupleBedroom3.enable();
      }
    });

    this.suiteBedroom.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberSuiteBedroom.disable();
        this.priceSuiteBedroom1.disable();
        this.priceSuiteBedroom2.disable();
        this.priceSuiteBedroom3.disable();
      } else {
        this.numberSuiteBedroom.enable();
        this.priceSuiteBedroom1.enable();
        this.priceSuiteBedroom2.enable();
        this.priceSuiteBedroom3.enable();
      }
    });

    this.presidentialBedroom.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberPresidentialBedroom.disable();
        this.pricePresidentialBedroom1.disable();
        this.pricePresidentialBedroom2.disable();
        this.pricePresidentialBedroom3.disable();
      } else {
        this.numberPresidentialBedroom.enable();
        this.pricePresidentialBedroom1.enable();
        this.pricePresidentialBedroom2.enable();
        this.pricePresidentialBedroom3.enable();
      }
    });

    this.eventsCompany.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberEventRooms.disable();
        this.capacityEventRooms.disable();
        this.priceEventRooms.disable();
        this.otherEventRooms.disable();
      } else {
        this.numberEventRooms.enable();
        this.capacityEventRooms.enable();
        this.priceEventRooms.enable();
        this.otherEventRooms.enable();
      }
    });

    this.webSite.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.urlWebSite.disable();
      } else {
        this.urlWebSite.enable();
      }
    });

    this.registerStep2FormFood
      .get('picnicZone')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormFood.get('numberPicnicZone').disable();
        } else {
          this.registerStep2FormFood.get('numberPicnicZone').enable();
        }
      });

    this.certificate.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.certificateRafting.disable();
        this.certificateRapel.disable();
        this.certificateSpeleology.disable();
        this.certificateParagliding.disable();
        this.certificateRide.disable();
        this.certificateCanyoning.disable();
      } else {
        this.certificateRafting.enable();
        this.certificateRapel.enable();
        this.certificateSpeleology.enable();
        this.certificateParagliding.enable();
        this.certificateRide.enable();
        this.certificateCanyoning.enable();
      }
    });

    this.transport.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.numberCar.disable();
      } else {
        this.numberCar.enable();
      }
    });

    this.optionRntTour.valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.rntTour.disable();
      } else {
        this.rntTour.enable();
      }
    });

    // Transport
    this.registerStep1FormTransport
      .get('optionRnt')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep1FormTransport.get('rnt').disable();
        } else {
          this.registerStep1FormTransport.get('rnt').enable();
        }
      });

    this.registerStep2FormTransport
      .get('certificate')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormTransport.get('certificateQuality').disable();
          this.registerStep2FormTransport.get('certificateISOption').disable();
          this.registerStep2FormTransport.get('certificateISO').disable();
          this.registerStep2FormTransport.get('certificateBIOption').disable();
          this.registerStep2FormTransport.get('certificateBIO').disable();
        } else {
          this.registerStep2FormTransport.get('certificateQuality').enable();
          this.registerStep2FormTransport.get('certificateISOption').enable();
          this.registerStep2FormTransport.get('certificateISO').enable();
          this.registerStep2FormTransport.get('certificateBIOption').enable();
          this.registerStep2FormTransport.get('certificateBIO').enable();
        }
      });

    this.registerStep2FormTransport
      .get('certificateISOption')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormTransport.get('certificateISO').disable();
        } else {
          this.registerStep2FormTransport.get('certificateISO').enable();
        }
      });

    this.registerStep2FormTransport
      .get('certificateBIOption')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormTransport.get('certificateBIO').disable();
        } else {
          this.registerStep2FormTransport.get('certificateBIO').enable();
        }
      });

    this.registerStep2FormTransport
      .get('transportBUS')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormTransport.get('numberBUS').disable();
        } else {
          this.registerStep2FormTransport.get('numberBUS').enable();
        }
      });

    this.registerStep2FormTransport
      .get('transportVANS')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormTransport.get('numberVANS').disable();
        } else {
          this.registerStep2FormTransport.get('numberVANS').enable();
        }
      });

    this.registerStep2FormTransport
      .get('transportCHIVAS')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormTransport.get('numberCHIVAS').disable();
        } else {
          this.registerStep2FormTransport.get('numberCHIVAS').enable();
        }
      });

    this.registerStep3Form.get('marketing').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep3Form.get('tripadvisor').disable();
        this.registerStep3Form.get('airbnb').disable();
        this.registerStep3Form.get('booking').disable();
        this.registerStep3Form.get('tolimaTravel').disable();
        this.registerStep3Form.get('atrapalo').disable();
      } else {
        this.registerStep3Form.get('tripadvisor').enable();
        this.registerStep3Form.get('airbnb').enable();
        this.registerStep3Form.get('booking').enable();
        this.registerStep3Form.get('tolimaTravel').enable();
        this.registerStep3Form.get('atrapalo').enable();
      }
    });

    // Form Guides

    this.registerStep1FormGuide
      .get('certificateLanguagesOption')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep1FormGuide.get('certificateLanguages').disable();
        } else {
          this.registerStep1FormGuide.get('certificateLanguages').enable();
        }
      });

    this.registerStep1FormGuide
      .get('optionRnt')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep1FormGuide.get('rnt').disable();
        } else {
          this.registerStep1FormGuide.get('rnt').enable();
        }
      });

    this.registerStep1FormGuide
      .get('optionNumberCarpro')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep1FormGuide.get('numberCardPro').disable();
        } else {
          this.registerStep1FormGuide.get('numberCardPro').enable();
        }
      });

    this.registerStep2FormGuide
      .get('certificate')
      .valueChanges.subscribe((value) => {
        if (value === 'no') {
          this.registerStep2FormGuide.get('certificatesList').disable();
        } else {
          this.registerStep2FormGuide.get('certificatesList').enable();
        }
      });

    // events

    this.registerStep2FormEvents.get('certificate').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('certificateQuality').disable();
        this.registerStep2FormEvents.get('certificateISOption').disable();
        this.registerStep2FormEvents.get('certificateISO').disable();
        this.registerStep2FormEvents.get('certificateBIOption').disable();
        this.registerStep2FormEvents.get('certificateBIO').disable();
        this.registerStep2FormEvents.get('otherCertificate').disable();
      } else {
        this.registerStep2FormEvents.get('certificateQuality').enable();
        this.registerStep2FormEvents.get('certificateISOption').enable();
        this.registerStep2FormEvents.get('certificateISO').enable();
        this.registerStep2FormEvents.get('certificateBIOption').enable();
        this.registerStep2FormEvents.get('certificateBIO').enable();
        this.registerStep2FormEvents.get('otherCertificate').enable();
      }
    });

    this.registerStep2FormEvents.get('certificateISOption').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('certificateISO').disable();
      } else {
        this.registerStep2FormEvents.get('certificateISO').enable();
      }
    });

    this.registerStep2FormEvents.get('certificateBIOption').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('certificateBIO').disable();
      } else {
        this.registerStep2FormEvents.get('certificateBIO').enable();
      }
    });

    this.registerStep2FormEvents.get('audit').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('numberAudit').disable();
        this.registerStep2FormEvents.get('numberAfor').disable();
        this.registerStep2FormEvents.get('priceAudit').disable();
      } else {
        this.registerStep2FormEvents.get('numberAudit').enable();
        this.registerStep2FormEvents.get('numberAfor').enable();
        this.registerStep2FormEvents.get('priceAudit').enable();
      }
    });

    this.registerStep2FormEvents.get('room').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('numberRoom').disable();
        this.registerStep2FormEvents.get('numberAforRoom').disable();
        this.registerStep2FormEvents.get('priceRoom').disable();
      } else {
        this.registerStep2FormEvents.get('numberRoom').enable();
        this.registerStep2FormEvents.get('numberAforRoom').enable();
        this.registerStep2FormEvents.get('priceRoom').enable();
      }
    });

    this.registerStep2FormEvents.get('roomU').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('numberRoomU').disable();
        this.registerStep2FormEvents.get('numberAforRoomU').disable();
        this.registerStep2FormEvents.get('priceRoomU').disable();
      } else {
        this.registerStep2FormEvents.get('numberRoomU').enable();
        this.registerStep2FormEvents.get('numberAforRoomU').enable();
        this.registerStep2FormEvents.get('priceRoomU').enable();
      }
    });

    this.registerStep2FormEvents.get('imperial').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('numberImperial').disable();
        this.registerStep2FormEvents.get('numberAforImperial').disable();
        this.registerStep2FormEvents.get('priceImperial').disable();
      } else {
        this.registerStep2FormEvents.get('numberImperial').enable();
        this.registerStep2FormEvents.get('numberAforImperial').enable();
        this.registerStep2FormEvents.get('priceImperial').enable();
      }
    });

    this.registerStep2FormEvents.get('coctel').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormEvents.get('numberCoctel').disable();
        this.registerStep2FormEvents.get('numberAforCoctel').disable();
        this.registerStep2FormEvents.get('priceCoctel').disable();
      } else {
        this.registerStep2FormEvents.get('numberCoctel').enable();
        this.registerStep2FormEvents.get('numberAforCoctel').enable();
        this.registerStep2FormEvents.get('priceCoctel').enable();
      }
    });


    // Atractive

    this.registerStep2FormAtractive.get('certificate').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormAtractive.get('certificateQuality').disable();
        this.registerStep2FormAtractive.get('certificateISOption').disable();
        this.registerStep2FormAtractive.get('certificateISO').disable();
        this.registerStep2FormAtractive.get('certificateBIOption').disable();
        this.registerStep2FormAtractive.get('certificateBIO').disable();
        this.registerStep2FormAtractive.get('otherCertificate').disable();
      } else {
        this.registerStep2FormAtractive.get('certificateQuality').enable();
        this.registerStep2FormAtractive.get('certificateISOption').enable();
        this.registerStep2FormAtractive.get('certificateISO').enable();
        this.registerStep2FormAtractive.get('certificateBIOption').enable();
        this.registerStep2FormAtractive.get('certificateBIO').enable();
        this.registerStep2FormAtractive.get('otherCertificate').enable();
      }
    });

    this.registerStep2FormAtractive.get('certificateISOption').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormAtractive.get('certificateISO').disable();
      } else {
        this.registerStep2FormAtractive.get('certificateISO').enable();
      }
    });

    this.registerStep2FormAtractive.get('certificateBIOption').valueChanges.subscribe((value) => {
      if (value === 'no') {
        this.registerStep2FormAtractive.get('certificateBIO').disable();
      } else {
        this.registerStep2FormAtractive.get('certificateBIO').enable();
      }
    });
  }

  goinput() {
    // if(this.registerStep1FormTour.get('time2Week').value < this.registerStep1FormTour.get('time1Week').value){
    //   Swal.fire({
    //     title: 'Advertencia!',
    //     text: 'Por favor verifique el horario de atencion entre semana.',
    //     icon: 'success',
    //     confirmButtonText: 'Aceptar',
    //   });
    // }else{
    // }
  }

  async save() {
    if (
      this.registerStep1Form.valid &&
      this.registerStep2Form.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.nameCompany.value + ' -' + img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.nameCompany.value + ' -' + img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
            });
          });
        }
        if (this.urls.length > 0) {
          let date1Week = new Date(
            this.registerStep1Form.get('time1Week').value
          );
          let date2Week = new Date(
            this.registerStep1Form.get('time2Week').value
          );
          let date1Weekend = new Date(
            this.registerStep1Form.get('time1Weekend').value
          );
          let date2Weekend = new Date(
            this.registerStep1Form.get('time2Weekend').value
          );
          this.registerStep1Form.value.time1Week =
            date1Week.getHours() + ' : ' + date1Week.getMinutes();
          this.registerStep1Form.value.time2Week =
            date2Week.getHours() + ' : ' + date2Week.getMinutes();
          this.registerStep1Form.value.time1Weekend =
            date1Weekend.getHours() + ' : ' + date1Weekend.getMinutes();
          this.registerStep1Form.value.time2Weekend =
            date2Weekend.getHours() + ' : ' + date2Weekend.getMinutes();
          this.registerStep2Form.value.servicios = [
            {
              restaurante: this.serviceCompany1.value,
              piscina: this.serviceCompany2.value,
              aire: this.serviceCompany3.value,
              television: this.serviceCompany4.value,
              internet: this.serviceCompany5.value,
              banos: this.serviceCompany6.value,
              salon: this.serviceCompany7.value,
              parqueadero: this.serviceCompany8.value,
            },
          ];
          let obj1 = Object.assign(
            this.registerStep1Form.value,
            this.registerStep2Form.value
          );
          let objEnviar = Object.assign(obj1, this.registerStep3Form.value);
          objEnviar.state = 'noPublicado';
          objEnviar.fotos = this.urls;
          objEnviar.categoryName = this.categories.filter(
            (item) => item.id === this.category.value
          )[0].name;
          objEnviar.ubication = this.ubications.filter(
            (item) => item.id === this.ubication.value
          )[0].name;
          this.firestoreService.create(objEnviar, 'prestadores').then(
            () => {
              this.isLoading = false;
              this.buttonTitle = 'Enviar y Registrar';
              this.imagenes = [];
              this.urls = [];
              Swal.fire({
                title: 'Guardado!',
                text: 'Se creo con exito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
              });
              this.registerStep1Form.reset();
              this.registerStep2Form.reset();
              this.registerStep3Form.reset();
              this.bsModalRef.hide();
            },
            (error) => {
              Swal.fire({
                title: 'Error!',
                text: 'Ocurrio un error',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  async saveFood() {
    let count = 0;
    if (
      this.registerStep1Form.valid &&
      this.registerStep2FormFood.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.registerStep1Form.value.nameCompany + ' -' + img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.registerStep1Form.value.nameCompany + ' -' + img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
              count = count + 1;
              if (count === this.imagenes.length) {
                let data = {};
                let date1Week = new Date(
                  this.registerStep1Form.get('time1Week').value
                );
                let date2Week = new Date(
                  this.registerStep1Form.get('time2Week').value
                );
                let date1Weekend = new Date(
                  this.registerStep1Form.get('time1Weekend').value
                );
                let date2Weekend = new Date(
                  this.registerStep1Form.get('time2Weekend').value
                );
                this.registerStep1Form.value.time1Week =
                  date1Week.getHours() + ' : ' + date1Week.getMinutes();
                this.registerStep1Form.value.time2Week =
                  date2Week.getHours() + ' : ' + date2Week.getMinutes();
                this.registerStep1Form.value.time1Weekend =
                  date1Weekend.getHours() + ' : ' + date1Weekend.getMinutes();
                this.registerStep1Form.value.time2Weekend =
                  date2Weekend.getHours() + ' : ' + date2Weekend.getMinutes();
                data[0] = this.registerStep1Form.value;
                data[1] = this.registerStep2FormFood.value;
                data[2] = this.registerStep3Form.value;
                data[2].fotos = this.urls;
                data[2].state = 'noPublicado';
                let obj1 = Object.assign(
                  this.registerStep1Form.value,
                  this.registerStep2FormFood.value
                );
                let objEnviar = Object.assign(
                  obj1,
                  this.registerStep3Form.value
                );
                objEnviar.state = 'noPublicado';
                objEnviar.fotos = this.urls;
                objEnviar.categoryName = this.categories.filter(
                  (item) => item.id === this.category.value
                )[0].name;
                objEnviar.ubication = this.ubications.filter(
                  (item) => item.id === this.ubication.value
                )[0].name;
                this.firestoreService.create(objEnviar, 'prestadores').then(
                  () => {
                    this.isLoading = false;
                    this.buttonTitle = 'Enviar y Registrar';
                    this.imagenes = [];
                    this.urls = [];
                    Swal.fire({
                      title: 'Guardado!',
                      text: 'Se creo con exito',
                      icon: 'success',
                      confirmButtonText: 'Aceptar',
                    });
                    this.registerStep1Form.reset();
                    this.registerStep2FormFood.reset();
                    this.registerStep3Form.reset();
                    this.bsModalRef.hide();
                  },
                  (error) => {
                    Swal.fire({
                      title: 'Error!',
                      text: 'Ocurrio un error',
                      icon: 'error',
                      confirmButtonText: 'Aceptar',
                    });
                  }
                );
              }
            });
          });
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  async saveTour() {
    let count = 0;
    if (
      this.registerStep1FormTour.valid &&
      this.registerStep2FormTour.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.registerStep1FormTour.value.nameCompany + ' -' + img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.registerStep1FormTour.value.nameCompany + ' -' + img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
              count = count + 1;
              if (count === this.imagenes.length) {
                // let data = this.updateModelTour(this.urls);
                let data = {};
                let date1Week = new Date(
                  this.registerStep1FormTour.get('time1Week').value
                );
                let date2Week = new Date(
                  this.registerStep1FormTour.get('time2Week').value
                );
                let date1Weekend = new Date(
                  this.registerStep1FormTour.get('time1Weekend').value
                );
                let date2Weekend = new Date(
                  this.registerStep1FormTour.get('time2Weekend').value
                );
                this.registerStep1FormTour.value.time1Week =
                  date1Week.getHours() + ' : ' + date1Week.getMinutes();
                this.registerStep1FormTour.value.time2Week =
                  date2Week.getHours() + ' : ' + date2Week.getMinutes();
                this.registerStep1FormTour.value.time1Weekend =
                  date1Weekend.getHours() + ' : ' + date1Weekend.getMinutes();
                this.registerStep1FormTour.value.time2Weekend =
                  date2Weekend.getHours() + ' : ' + date2Weekend.getMinutes();
                data[0] = this.registerStep1FormTour.value;
                data[1] = this.registerStep2FormTour.value;
                data[2] = this.registerStep3Form.value;
                data[2].fotos = this.urls;
                data[2].state = 'noPublicado';
                let obj1 = Object.assign(
                  this.registerStep1FormTour.value,
                  this.registerStep2FormTour.value
                );
                let objEnviar = Object.assign(
                  obj1,
                  this.registerStep3Form.value
                );
                objEnviar.state = 'noPublicado';
                objEnviar.fotos = this.urls;
                objEnviar.categoryName = this.categories.filter(
                  (item) => item.id === this.category.value
                )[0].name;
                objEnviar.ubication = this.ubications.filter(
                  (item) => item.id === this.ubication.value
                )[0].name;
                this.firestoreService.create(objEnviar, 'agencias').then(
                  () => {
                    this.isLoading = false;
                    this.buttonTitle = 'Enviar y Registrar';
                    this.imagenes = [];
                    this.urls = [];
                    Swal.fire({
                      title: 'Guardado!',
                      text: 'Se creo con exito',
                      icon: 'success',
                      confirmButtonText: 'Aceptar',
                    });
                    this.registerStep1FormTour.reset();
                    this.registerStep2FormTour.reset();
                    this.registerStep3Form.reset();
                    this.bsModalRef.hide();
                  },
                  (error) => {
                    Swal.fire({
                      title: 'Error!',
                      text: 'Ocurrio un error',
                      icon: 'error',
                      confirmButtonText: 'Aceptar',
                    });
                  }
                );
              }
            });
          });
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  async saveTransport() {
    if (
      this.registerStep1FormTransport.valid &&
      this.registerStep2FormTransport.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.registerStep1FormTransport.value.nameCompany +
            ' -' +
            img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.registerStep1FormTransport.value.nameCompany +
            ' -' +
            img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
            });
          });
        }
        if (this.urls.length > 0) {
          // let data = this.updateModelTour(this.urls);
          let data = {};
          let date1Week = new Date(
            this.registerStep1FormTransport.get('time1Week').value
          );
          let date2Week = new Date(
            this.registerStep1FormTransport.get('time2Week').value
          );
          let date1Weekend = new Date(
            this.registerStep1FormTransport.get('time1Weekend').value
          );
          let date2Weekend = new Date(
            this.registerStep1FormTransport.get('time2Weekend').value
          );
          this.registerStep1FormTransport.value.time1Week =
            date1Week.getHours() + ' : ' + date1Week.getMinutes();
          this.registerStep1FormTransport.value.time2Week =
            date2Week.getHours() + ' : ' + date2Week.getMinutes();
          this.registerStep1FormTransport.value.time1Weekend =
            date1Weekend.getHours() + ' : ' + date1Weekend.getMinutes();
          this.registerStep1FormTransport.value.time2Weekend =
            date2Weekend.getHours() + ' : ' + date2Weekend.getMinutes();
          data[0] = this.registerStep1FormTransport.value;
          data[1] = this.registerStep2FormTransport.value;
          data[2] = this.registerStep3Form.value;
          data[2].fotos = this.urls;
          data[2].state = 'noPublicado';
          let obj1 = Object.assign(
            this.registerStep1FormTransport.value,
            this.registerStep2FormTransport.value
          );
          let objEnviar = Object.assign(obj1, this.registerStep3Form.value);
          objEnviar.state = 'noPublicado';
          objEnviar.fotos = this.urls;
          objEnviar.categoryName = this.categories.filter(
            (item) => item.id === this.category.value
          )[0].name;
          objEnviar.ubication = this.ubications.filter(
            (item) => item.id === this.ubication.value
          )[0].name;
          this.firestoreService.create(objEnviar, 'transportes').then(
            () => {
              this.isLoading = false;
              this.buttonTitle = 'Enviar y Registrar';
              this.imagenes = [];
              this.urls = [];
              Swal.fire({
                title: 'Guardado!',
                text: 'Se creo con exito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
              });
              this.registerStep1FormTransport.reset();
              this.registerStep2FormTransport.reset();
              this.registerStep3Form.reset();
              this.bsModalRef.hide();
            },
            (error) => {
              Swal.fire({
                title: 'Error!',
                text: 'Ocurrio un error',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  async saveGuide() {
    if (
      this.registerStep1FormGuide.valid &&
      this.registerStep2FormGuide.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.registerStep1FormGuide.value.namePerson + ' -' + img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.registerStep1FormGuide.value.namePerson + ' -' + img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
            });
          });
        }
        if (this.urls.length > 0) {
          // let data = this.updateModelTour(this.urls);
          let data = {};
          data[0] = this.registerStep1FormGuide.value;
          data[1] = this.registerStep2FormGuide.value;
          data[2] = this.registerStep3Form.value;
          data[2].fotos = this.urls;
          data[2].state = 'noPublicado';
          let obj1 = Object.assign(
            this.registerStep1FormGuide.value,
            this.registerStep2FormGuide.value
          );
          let objEnviar = Object.assign(obj1, this.registerStep3Form.value);
          objEnviar.state = 'noPublicado';
          objEnviar.fotos = this.urls;
          objEnviar.categoryName = this.categories.filter(
            (item) => item.id === this.category.value
          )[0].name;
          objEnviar.ubication = this.ubications.filter(
            (item) => item.id === this.ubication.value
          )[0].name;
          this.firestoreService.create(objEnviar, 'guias').then(
            () => {
              this.isLoading = false;
              this.buttonTitle = 'Enviar y Registrar';
              this.imagenes = [];
              this.urls = [];
              Swal.fire({
                title: 'Guardado!',
                text: 'Se creo con exito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
              });
              this.registerStep1FormGuide.reset();
              this.registerStep2FormGuide.reset();
              this.registerStep3Form.reset();
              this.bsModalRef.hide();
            },
            (error) => {
              Swal.fire({
                title: 'Error!',
                text: 'Ocurrio un error',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  async saveEvent() {
    if (
      this.registerStep1Form.valid &&
      this.registerStep2FormEvents.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.registerStep1Form.value.namePerson + ' -' + img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.registerStep1Form.value.namePerson + ' -' + img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
            });
          });
        }
        if (this.urls.length > 0) {
          // let data = this.updateModelTour(this.urls);
          let data = {};
          let date1Week = new Date(
            this.registerStep1Form.get('time1Week').value
          );
          let date2Week = new Date(
            this.registerStep1Form.get('time2Week').value
          );
          let date1Weekend = new Date(
            this.registerStep1Form.get('time1Weekend').value
          );
          let date2Weekend = new Date(
            this.registerStep1Form.get('time2Weekend').value
          );
          this.registerStep1Form.value.time1Week =
            date1Week.getHours() + ' : ' + date1Week.getMinutes();
          this.registerStep1Form.value.time2Week =
            date2Week.getHours() + ' : ' + date2Week.getMinutes();
          this.registerStep1Form.value.time1Weekend =
            date1Weekend.getHours() + ' : ' + date1Weekend.getMinutes();
          this.registerStep1Form.value.time2Weekend =
            date2Weekend.getHours() + ' : ' + date2Weekend.getMinutes();
          data[0] = this.registerStep1Form.value;
          data[1] = this.registerStep2FormEvents.value;
          data[2] = this.registerStep3Form.value;
          data[2].fotos = this.urls;
          data[2].state = 'noPublicado';
          let obj1 = Object.assign(
            this.registerStep1Form.value,
            this.registerStep2FormEvents.value
          );
          let objEnviar = Object.assign(obj1, this.registerStep3Form.value);
          objEnviar.state = 'noPublicado';
          objEnviar.fotos = this.urls;
          objEnviar.categoryName = this.categories.filter(
            (item) => item.id === this.category.value
          )[0].name;
          objEnviar.ubication = this.ubications.filter(
            (item) => item.id === this.ubication.value
          )[0].name;
          this.firestoreService.create(objEnviar, 'organizador').then(
            () => {
              this.isLoading = false;
              this.buttonTitle = 'Enviar y Registrar';
              this.imagenes = [];
              this.urls = [];
              Swal.fire({
                title: 'Guardado!',
                text: 'Se creo con exito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
              });
              this.registerStep1Form.reset();
              this.registerStep2FormEvents.reset();
              this.registerStep3Form.reset();
              this.bsModalRef.hide();
            },
            (error) => {
              Swal.fire({
                title: 'Error!',
                text: 'Ocurrio un error',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  async saveAtractive() {
    if (
      this.registerStep1Form.valid &&
      this.registerStep2FormAtractive.valid &&
      this.registerStep3Form.valid
    ) {
      this.isLoading = true;
      this.buttonTitle = 'Cargando...';
      if (this.imagenes.length > 0) {
        this.imagenes.push({
          nombre: 'filename',
          archivo: null,
          tipo: 'last',
        })
        for (let img of this.imagenes) {
          let tarea = this.firestoreService.tareaCloudStorage(
            this.registerStep1Form.value.namePerson + ' -' + img.nombre,
            img.archivo
          );
          let referencia = this.firestoreService.referenciaCloudStorage(
            this.registerStep1Form.value.namePerson + ' -' + img.nombre
          );
          await tarea.then(() => {
            referencia.getDownloadURL().subscribe((URL) => {
              this.urls.push({
                tipo: img.tipo,
                url: URL,
              });
            });
          });
        }
        if (this.urls.length > 0) {
          // let data = this.updateModelTour(this.urls);
          let data = {};
          let date1Week = new Date(
            this.registerStep1Form.get('time1Week').value
          );
          let date2Week = new Date(
            this.registerStep1Form.get('time2Week').value
          );
          let date1Weekend = new Date(
            this.registerStep1Form.get('time1Weekend').value
          );
          let date2Weekend = new Date(
            this.registerStep1Form.get('time2Weekend').value
          );
          this.registerStep1Form.value.time1Week =
            date1Week.getHours() + ' : ' + date1Week.getMinutes();
          this.registerStep1Form.value.time2Week =
            date2Week.getHours() + ' : ' + date2Week.getMinutes();
          this.registerStep1Form.value.time1Weekend =
            date1Weekend.getHours() + ' : ' + date1Weekend.getMinutes();
          this.registerStep1Form.value.time2Weekend =
            date2Weekend.getHours() + ' : ' + date2Weekend.getMinutes();
          data[0] = this.registerStep1Form.value;
          data[1] = this.registerStep2FormAtractive.value;
          data[2] = this.registerStep3Form.value;
          data[2].fotos = this.urls;
          data[2].state = 'noPublicado';
          let obj1 = Object.assign(
            this.registerStep1Form.value,
            this.registerStep2FormAtractive.value
          );
          let objEnviar = Object.assign(obj1, this.registerStep3Form.value);
          objEnviar.state = 'noPublicado';
          objEnviar.fotos = this.urls;
          objEnviar.categoryName = this.categories.filter(
            (item) => item.id === this.category.value
          )[0].name;
          objEnviar.ubication = this.ubications.filter(
            (item) => item.id === this.ubication.value
          )[0].name;
          this.firestoreService.create(objEnviar, 'organizador').then(
            () => {
              this.isLoading = false;
              this.buttonTitle = 'Enviar y Registrar';
              this.imagenes = [];
              this.urls = [];
              Swal.fire({
                title: 'Guardado!',
                text: 'Se creo con exito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
              });
              this.registerStep1Form.reset();
              this.registerStep2FormAtractive.reset();
              this.registerStep3Form.reset();
              this.bsModalRef.hide();
            },
            (error) => {
              Swal.fire({
                title: 'Error!',
                text: 'Ocurrio un error',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El logo es obligatorio',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Formulario incompleto',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  //Evento que se gatilla cuando el input de tipo archivo cambia
  public cambioArchivo(event, type) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size <= 1000000) {
          /*   this.imagenes.push({
              nombre: event.target.files[i].name,
              archivo: event.target.files[i],
              tipo: type,
            }); */
          switch (type) {
            case 'logotipo':
              this.imagenes[0] =
              {
                nombre: event.target.files[0].name,
                archivo: event.target.files[0],
                tipo: type,
              };
              break;
            case 'foto1':
              this.imagenes[1] =
              {
                nombre: event.target.files[0].name,
                archivo: event.target.files[0],
                tipo: type,
              };
              break;
            case 'foto2':
              this.imagenes[2] =
              {
                nombre: event.target.files[0].name,
                archivo: event.target.files[0],
                tipo: type,
              };
              break;
            case 'foto3':
              this.imagenes[3] =
              {
                nombre: event.target.files[0].name,
                archivo: event.target.files[0],
                tipo: type,
              };
              break;
          }
        } else {
          switch (type) {
            case 'logotipo':
              this.logo = '';
              break;
            case 'foto1':
              this.foto1 = '';
              break;
            case 'foto2':
              this.foto2 = '';
              break;
            case 'foto3':
              this.foto3 = '';
              break;
          }
          Swal.fire({
            title: 'Error!',
            text: 'La imagen es muy pesada, peso maximo de 1MB',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      }
    }
  }

  get category(): AbstractControl {
    return this.categoryForm.get('category');
  }

  get subCategory(): AbstractControl {
    return this.categoryForm.get('subCategory');
  }

  get ubication(): AbstractControl {
    return this.categoryForm.get('ubication');
  }

  // registerStep1Form
  get nameCompany() {
    return this.registerStep1Form.get('nameCompany');
  }
  get optionRnt() {
    return this.registerStep1Form.get('optionRnt');
  }
  get rnt() {
    return this.registerStep1Form.get('rnt');
  }
  get optionZone() {
    return this.registerStep1Form.get('optionZone');
  }
  get addressCompany() {
    return this.registerStep1Form.get('addressCompany');
  }
  get distanceCityKM() {
    return this.registerStep1Form.get('distanceCityKM');
  }
  get KMCity() {
    return this.registerStep1Form.get('KMCity');
  }
  get timeDistanceCity() {
    return this.registerStep1Form.get('timeDistanceCity');
  }
  get DistanceFly() {
    return this.registerStep1Form.get('DistanceFly');
  }
  get KMFly() {
    return this.registerStep1Form.get('KMFly');
  }
  get timeDistanceFly() {
    return this.registerStep1Form.get('timeDistanceFly');
  }
  get DistanceCar() {
    return this.registerStep1Form.get('DistanceCar');
  }
  get KMCar() {
    return this.registerStep1Form.get('KMCar');
  }
  get timeDistanceCar() {
    return this.registerStep1Form.get('timeDistanceCar');
  }
  get locationEstablishment() {
    return this.registerStep1Form.get('locationEstablishment');
  }
  get optionLegal() {
    return this.registerStep1Form.get('optionLegal');
  }
  get addressCorrespondence() {
    return this.registerStep1Form.get('addressCorrespondence');
  }
  get idPerson() {
    return this.registerStep1Form.get('idPerson');
  }
  get namePerson() {
    return this.registerStep1Form.get('namePerson');
  }
  get cellphone() {
    return this.registerStep1Form.get('cellphone');
  }
  get email() {
    return this.registerStep1Form.get('email');
  }
  get cellphoneCompany() {
    return this.registerStep1Form.get('cellphoneCompany');
  }
  get access() {
    return this.registerStep1Form.get('access');
  }
  get day1() {
    return this.registerStep1Form.get('day1');
  }
  get day2() {
    return this.registerStep1Form.get('day2');
  }
  get day3() {
    return this.registerStep1Form.get('day3');
  }
  get day4() {
    return this.registerStep1Form.get('day4');
  }
  get day5() {
    return this.registerStep1Form.get('day5');
  }
  get day6() {
    return this.registerStep1Form.get('day6');
  }
  get day7() {
    return this.registerStep1Form.get('day7');
  }
  get day8() {
    return this.registerStep1Form.get('day8');
  }
  get employedDirect() {
    return this.registerStep1Form.get('employedDirect');
  }
  get employedTemp() {
    return this.registerStep1Form.get('employedTemp');
  }

  // registerStep2Form
  get priceAVGCompany() {
    return this.registerStep2Form.get('priceAVGCompany');
  }
  get numberRoomsCompany() {
    return this.registerStep2Form.get('numberRoomsCompany');
  }
  get numberBedRoomsCompany() {
    return this.registerStep2Form.get('numberBedRoomsCompany');
  }
  get simpleBedroom() {
    return this.registerStep2Form.get('simpleBedroom');
  }
  get numberSimpleBedroom() {
    return this.registerStep2Form.get('numberSimpleBedroom');
  }
  get priceSimpleBedroom1() {
    return this.registerStep2Form.get('priceSimpleBedroom1');
  }
  get priceSimpleBedroom2() {
    return this.registerStep2Form.get('priceSimpleBedroom2');
  }
  get priceSimpleBedroom3() {
    return this.registerStep2Form.get('priceSimpleBedroom3');
  }
  get doubleBedroom() {
    return this.registerStep2Form.get('doubleBedroom');
  }
  get numberDoubleBedroom() {
    return this.registerStep2Form.get('numberDoubleBedroom');
  }
  get priceDoubleBedroom1() {
    return this.registerStep2Form.get('priceDoubleBedroom1');
  }
  get priceDoubleBedroom2() {
    return this.registerStep2Form.get('priceDoubleBedroom2');
  }
  get priceDoubleBedroom3() {
    return this.registerStep2Form.get('priceDoubleBedroom3');
  }
  get tripleBedroom() {
    return this.registerStep2Form.get('tripleBedroom');
  }
  get numberTripleBedroom() {
    return this.registerStep2Form.get('numberTripleBedroom');
  }
  get priceTripleBedroom1() {
    return this.registerStep2Form.get('priceTripleBedroom1');
  }
  get priceTripleBedroom2() {
    return this.registerStep2Form.get('priceTripleBedroom2');
  }
  get priceTripleBedroom3() {
    return this.registerStep2Form.get('priceTripleBedroom3');
  }
  get quadrupleBedroom() {
    return this.registerStep2Form.get('quadrupleBedroom');
  }
  get numberQuadrupleBedroom() {
    return this.registerStep2Form.get('numberQuadrupleBedroom');
  }
  get priceQuadrupleBedroom1() {
    return this.registerStep2Form.get('priceQuadrupleBedroom1');
  }
  get priceQuadrupleBedroom2() {
    return this.registerStep2Form.get('priceQuadrupleBedroom2');
  }
  get priceQuadrupleBedroom3() {
    return this.registerStep2Form.get('priceQuadrupleBedroom3');
  }
  get suiteBedroom() {
    return this.registerStep2Form.get('suiteBedroom');
  }
  get numberSuiteBedroom() {
    return this.registerStep2Form.get('numberSuiteBedroom');
  }
  get priceSuiteBedroom1() {
    return this.registerStep2Form.get('priceSuiteBedroom1');
  }
  get priceSuiteBedroom2() {
    return this.registerStep2Form.get('priceSuiteBedroom2');
  }
  get priceSuiteBedroom3() {
    return this.registerStep2Form.get('priceSuiteBedroom3');
  }
  get presidentialBedroom() {
    return this.registerStep2Form.get('presidentialBedroom');
  }
  get numberPresidentialBedroom() {
    return this.registerStep2Form.get('numberPresidentialBedroom');
  }
  get pricePresidentialBedroom1() {
    return this.registerStep2Form.get('pricePresidentialBedroom1');
  }
  get pricePresidentialBedroom2() {
    return this.registerStep2Form.get('pricePresidentialBedroom2');
  }
  get pricePresidentialBedroom3() {
    return this.registerStep2Form.get('pricePresidentialBedroom3');
  }
  get otherBedroom() {
    return this.registerStep2Form.get('otherBedroom');
  }
  get eventsCompany() {
    return this.registerStep2Form.get('eventsCompany');
  }
  get numberEventRooms() {
    return this.registerStep2Form.get('numberEventRooms');
  }
  get capacityEventRooms() {
    return this.registerStep2Form.get('capacityEventRooms');
  }
  get priceEventRooms() {
    return this.registerStep2Form.get('priceEventRooms');
  }
  get otherEventRooms() {
    return this.registerStep2Form.get('otherEventRooms');
  }
  get audiovisualAids() {
    return this.registerStep2Form.get('audiovisualAids');
  }
  get sound() {
    return this.registerStep2Form.get('sound');
  }
  get microphone() {
    return this.registerStep2Form.get('microphone');
  }
  get waterCoffee() {
    return this.registerStep2Form.get('waterCoffee');
  }
  get Catering() {
    return this.registerStep2Form.get('Catering');
  }
  get otherEventService() {
    return this.registerStep2Form.get('otherEventService');
  }
  get serviceCompany1() {
    return this.registerStep2Form.get('restaurante');
  }
  get serviceCompany2() {
    return this.registerStep2Form.get('piscina');
  }
  get serviceCompany3() {
    return this.registerStep2Form.get('aire');
  }
  get serviceCompany4() {
    return this.registerStep2Form.get('television');
  }
  get serviceCompany5() {
    return this.registerStep2Form.get('internet');
  }
  get serviceCompany6() {
    return this.registerStep2Form.get('banos');
  }
  get serviceCompany7() {
    return this.registerStep2Form.get('salon');
  }
  get serviceCompany8() {
    return this.registerStep2Form.get('parqueadero');
  }
  get otherServiceCompany() {
    return this.registerStep2Form.get('otherServiceCompany');
  }

  // registerStep3Form
  get webSite() {
    return this.registerStep3Form.get('webSite');
  }
  get urlWebSite() {
    return this.registerStep3Form.get('urlWebSite');
  }
  get payWebSite() {
    return this.registerStep3Form.get('payWebSite');
  }
  get bookWebSite() {
    return this.registerStep3Form.get('bookWebSite');
  }
  get facebook() {
    return this.registerStep3Form.get('facebook');
  }
  get twitter() {
    return this.registerStep3Form.get('twitter');
  }
  get instagram() {
    return this.registerStep3Form.get('instagram');
  }
  get otherSocialMedia() {
    return this.registerStep3Form.get('otherSocialMedia');
  }
  get marketing() {
    return this.registerStep3Form.get('marketing');
  }
  get tripadvisor() {
    return this.registerStep3Form.get('tripadvisor');
  }
  get airbnb() {
    return this.registerStep3Form.get('airbnb');
  }
  get booking() {
    return this.registerStep3Form.get('booking');
  }
  get tolimaTravel() {
    return this.registerStep3Form.get('tolimaTravel');
  }
  get atrapalo() {
    return this.registerStep3Form.get('atrapalo');
  }
  get recomendations() {
    return this.registerStep3Form.get('recomendations');
  }
  get guild() {
    return this.registerStep3Form.get('guild');
  }

  // RegisterForm2Food

  get priceAVGCompanyFood() {
    return this.registerStep2FormFood.get('priceAVGCompany');
  }
  get numberTableCompany() {
    return this.registerStep2FormFood.get('numberTableCompany');
  }
  get numberTable2() {
    return this.registerStep2FormFood.get('numberTable2');
  }
  get numberTable4() {
    return this.registerStep2FormFood.get('numberTable4');
  }
  get numberTable6() {
    return this.registerStep2FormFood.get('numberTable6');
  }
  get numberTableOther() {
    return this.registerStep2FormFood.get('numberTableOther');
  }
  get restaurantArea() {
    return this.registerStep2FormFood.get('restaurantArea');
  }
  get picnicZone() {
    return this.registerStep2FormFood.get('picnicZone');
  }
  get numberPicnicZone() {
    return this.registerStep2FormFood.get('numberPicnicZone');
  }
  get menu() {
    return this.registerStep2FormFood.get('menu');
  }

  // Register form tour 1
  get optionRntTour() {
    return this.registerStep1FormTour.get('optionRnt');
  }

  get rntTour() {
    return this.registerStep1FormTour.get('rnt');
  }

  get digitalAgent() {
    return this.registerStep1FormTour.get('digitalAgent');
  }

  // REgister form tour 2
  get internationalEmissive() {
    return this.registerStep2FormTour.get('internationalEmissive');
  }
  get nationalEmissive() {
    return this.registerStep2FormTour.get('nationalEmissive');
  }
  get tolimaInternationalEmissive() {
    return this.registerStep2FormTour.get('tolimaInternationalEmissive');
  }
  get tolimaNationalEmissive() {
    return this.registerStep2FormTour.get('tolimaNationalEmissive');
  }
  get departmentTour() {
    return this.registerStep2FormTour.get('departmentTour');
  }
  get otherTour() {
    return this.registerStep2FormTour.get('otherTour');
  }
  get mountain() {
    return this.registerStep2FormTour.get('mountain');
  }
  get canyoning() {
    return this.registerStep2FormTour.get('canyoning');
  }
  get rafting() {
    return this.registerStep2FormTour.get('rafting');
  }
  get rappel() {
    return this.registerStep2FormTour.get('rappel');
  }
  get canopy() {
    return this.registerStep2FormTour.get('canopy');
  }
  get trekking() {
    return this.registerStep2FormTour.get('trekking');
  }
  get speleology() {
    return this.registerStep2FormTour.get('speleology');
  }
  get ride() {
    return this.registerStep2FormTour.get('ride');
  }
  get mountainBiking() {
    return this.registerStep2FormTour.get('mountainBiking');
  }
  get paragliding() {
    return this.registerStep2FormTour.get('paragliding');
  }
  get certificate() {
    return this.registerStep2FormTour.get('certificate');
  }
  get certificateRafting() {
    return this.registerStep2FormTour.get('certificateRafting');
  }
  get certificateRapel() {
    return this.registerStep2FormTour.get('certificateRapel');
  }
  get certificateSpeleology() {
    return this.registerStep2FormTour.get('certificateSpeleology');
  }
  get certificateParagliding() {
    return this.registerStep2FormTour.get('certificateParagliding');
  }
  get certificateRide() {
    return this.registerStep2FormTour.get('certificateRide');
  }
  get certificateCanyoning() {
    return this.registerStep2FormTour.get('certificateCanyoning');
  }
  get otherCertificate() {
    return this.registerStep2FormTour.get('otherCertificate');
  }
  get transport() {
    return this.registerStep2FormTour.get('transport');
  }
  get numberCar() {
    return this.registerStep2FormTour.get('numberCar');
  }
  get regionalService() {
    return this.registerStep2FormTour.get('regionalService');
  }
  get nationalService() {
    return this.registerStep2FormTour.get('nationalService');
  }
}

<div class="header" id="header-web">
    <nav class="navbar navbar-expand-lg px-2">
        <div class="container-xl">
            <!-- Navbar toggle -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
            <!-- Collapse -->
            <div class="collapse navbar-collapse" id="navbarText">
                <!-- Logo -->
                  <h1 class="site-title">
                    <a class="navbar-brand" [ngClass]="{'navbar-brand-invert': showNav }" (click)="goTo()">
                      <img src="../../../assets/images/logos/LOGO_PAG.svg" alt="Descubre Ibagué" title="{{'home' | translate}}" width="239" height="60" sizes="239px" />
                    </a>
                 </h1>

                <!-- Nav -->
                <div class="navbar-nav mx-lg-auto cont-menu">
                    <a class="navbar-brand" [ngClass]="{'navbar-brand-invert': showNav }" (click)="scroll('ofertas')">{{'touristic_offer' | translate}}</a>
                    <a class="navbar-brand" [ngClass]="{'navbar-brand-invert': showNav }" (click)="scroll('rutas')">{{'routes' | translate}}</a>
                    <a class="navbar-brand" [ngClass]="{'navbar-brand-invert': showNav }" (click)="scroll('contacto')">{{'contact_us' | translate}}</a>
                </div>
                <!-- Action -->
                <div class="d-flex align-items-lg-center mt-3 mt-lg-0">
                    <span [ngClass]="{'navbar-text':!showNav, 'navbar-text-inverse': showNav}">
                    <ul class="navbar-nav">
                      <li>
                        <p class="mr-5 mb-0">{{'core.tourist_provider'| translate}}</p>
                      </li>
                      <li class="navbar-text">
                        <a (click)="openModalRegister()" class="register_menu">{{'core.register' | translate}}</a>
                      </li>
                    </ul>
                  </span>
                </div>
                <ul class="form-inline my-2 my-lg-0">
                    <select class="form-control mr-sm-2" #langSelect (change)="changeLang(langSelect.value)">
                    <option value="" *ngFor="let lang of langs" [value]="lang">{{lang}}</option>
                  </select>
                </ul>
            </div>
        </div>
    </nav>
</div>

import { Component, HostListener, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegisterComponent } from 'src/app/home/register/register.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  langs: string[] = [];
  faHome = faHome;
  modalRef: BsModalRef;
  showNav:boolean = false
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
   let scroll = document.documentElement.scrollTop || document.body.scrollTop
   if(scroll > 0){
    this.showNav = true    
   }else{
    this.showNav = false
   }
  }
  constructor(private modalService: BsModalService,
              private router: Router, private translate:TranslateService) {
      this.langs = this.translate.getLangs()
    }

  ngOnInit(): void {
  }

  openModalRegister() {
    this.modalRef = this.modalService.show(RegisterComponent,{backdrop: 'static', class: 'modal-lg'});
  }

  scroll(id){
    this.router.navigate(['/'], { fragment: id });
    setTimeout(()=>{
      const element = document.getElementById(id);
      const yOffset = -120; 
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    },100)
  }
  
  goTo(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.router.navigate(['/']);
  }

  // Llamar la funcion use dinamicamente
  changeLang(lang:string){
    this.translate.use(lang)
  }

}

<div class="modal-lg">
    <div class="modal-header">
        <h1 class="modal-title m-auto text-center">
            BIENVENIDOS PRESTADORES TURÍSTICOS IBAGUEREÑOS
        </h1>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2 class="text-center gray">
            Digita el siguiente formulario en solo 3 pasos:
        </h2>
        <div class="container">
            <form [formGroup]="categoryForm">
                <div class="form-row">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="category" placeholder="Elige tu categoría">
                                <mat-option value="">Selecciona una categoría</mat-option>
                                <mat-option *ngFor="let category of categories" [value]="category.id">
                                    <fa-icon class="mr-2" [icon]="category.icon"></fa-icon>
                                    <span>{{ category.name }}</span>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngFor="let error of categoryForm.get('category').errors | keyvalue">
                                <div [ngSwitch]="error.key">
                                    <ng-template [ngSwitchCase]="'required'">
                                        Requerido
                                    </ng-template>
                                    <ng-template ngSwitchDefault>
                                        {{error.key}}
                                    </ng-template>
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="subCategory" placeholder="Elige tu subcategoría">
                                <mat-option value="">Selecciona una subcategoría</mat-option>
                                <mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id">
                                    <fa-icon class="mr-2" [icon]="subcategory.icon"></fa-icon>
                                    <span>{{ subcategory.name }}</span>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngFor="let error of categoryForm.get('subCategory').errors | keyvalue">
                                <div [ngSwitch]="error.key">
                                    <ng-template [ngSwitchCase]="'required'">
                                        Requerido
                                    </ng-template>
                                    <ng-template ngSwitchDefault>
                                        {{error.key}}
                                    </ng-template>
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="ubication" placeholder="Elige tu ubicación">
                                <mat-option value="">Selecciona una ubicación</mat-option>
                                <mat-option *ngFor="let ubication of ubications" [value]="ubication.id">
                                    <fa-icon class="mr-2" [icon]="ubication.icon"></fa-icon>
                                    <span>{{ ubication.name }}</span>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngFor="let error of categoryForm.get('ubication').errors | keyvalue">
                                <div [ngSwitch]="error.key">
                                    <ng-template [ngSwitchCase]="'required'">
                                        Requerido
                                    </ng-template>
                                    <ng-template ngSwitchDefault>
                                        {{error.key}}
                                    </ng-template>
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <!-- Pasos para alojammientos -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 1 && subCategory.value && ubication.value">
            <mat-step [stepControl]="registerStep1Form">
                <form [formGroup]="registerStep1Form">
                    <ng-template matStepLabel class="steps">Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de la empresa</mat-label>
                                <input formControlName="nameCompany" matInput placeholder="Mi empresa" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('nameCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionRnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-sm-12 col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Ubicación exacta del establecimiento
                            </h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4 class="subtittle">Ubicación del establecimiento en Google Maps</h4>

                        </div>
                        <div class="col-md-8">

                            <mat-form-field appearance="outline">
                                <mat-label>Insertar iframe de ubicación del establecimiento en Google Maps</mat-label>
                                <input formControlName="locationEstablishment" matInput
                                    placeholder="Insertar iframe de ubicación del establecimiento en Google Maps"
                                    required />
                                <mat-hint><a
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">Mira aquí como insertar
                                        el iframe de
                                        ubicación del establecimiento en Google Maps</a> </mat-hint>

                                <mat-icon matSuffix
                                    matTooltip="Mira aquí como insertar el iframe de ubicación del establecimiento en Google Maps.">
                                    <a class="link_video"
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">help_outline</a>
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-4">
                            <label>¿Está formalizado?</label>
                            <mat-radio-group formControlName="optionLegal">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionLegal').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Accesibilidad</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Indicaciones para el acceso</mat-label>
                                <textarea formControlName="access" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep1Form.get('access').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Horarios de atención entre semana</h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day1" labelPosition="before">Lunes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day2" labelPosition="before">Martes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day3" labelPosition="before">Miercoles
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day4" labelPosition="before">Jueves
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day5" labelPosition="before">Viernes
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Week" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Week" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Horarios de atención fines de semanas y festivos
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day6" labelPosition="before">Sabado
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day7" labelPosition="before">Domingo
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day8" labelPosition="before">Festivos
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Weekend" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Weekend" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Estructura empresarial</h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4>Empleados vinculados a la empresa</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación directa</mat-label>
                                <input formControlName="employedDirect" matInput placeholder="Vinculación directa"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación directa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedDirect').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación temporal</mat-label>
                                <input formControlName="employedTemp" matInput placeholder="Vinculación temporal"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación temporal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedTemp').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep2Form">
                <form [formGroup]="registerStep2Form">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Capacidad</h3>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberRoomsCompany" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberRoomsCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de camas</mat-label>
                                <input formControlName="numberBedRoomsCompany" matInput placeholder="Número de camas"
                                    required />
                                <mat-icon matSuffix matTooltip="Número de camas.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberBedRoomsCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Tipos de acomodación</h3>
                        </div>
                        <div class="col-md-4">
                            <label>¿Habitación sencilla?</label>
                            <mat-radio-group formControlName="simpleBedroom" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('simpleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberSimpleBedroom" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberSimpleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa público</mat-label>
                                <input formControlName="priceSimpleBedroom1" matInput placeholder="Tarifa público"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa público.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceSimpleBedroom1').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa corporativo</mat-label>
                                <input formControlName="priceSimpleBedroom2" matInput placeholder="Tarifa corporativo"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa corporativo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceSimpleBedroom2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa para agencias de viajes</mat-label>
                                <input formControlName="priceSimpleBedroom3" matInput
                                    placeholder="Tarifa para agencias de viajes" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa para agencias de viajes.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceSimpleBedroom3').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label>¿Habitación doble?</label>
                            <mat-radio-group formControlName="doubleBedroom" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('doubleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberDoubleBedroom" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberDoubleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa público</mat-label>
                                <input formControlName="priceDoubleBedroom1" matInput placeholder="Tarifa público"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa público.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceDoubleBedroom1').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa corporativo</mat-label>
                                <input formControlName="priceDoubleBedroom2" matInput placeholder="Tarifa corporativo"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa corporativo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceDoubleBedroom2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa para agencias de viajes</mat-label>
                                <input formControlName="priceDoubleBedroom3" matInput
                                    placeholder="Tarifa para agencias de viajes" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa para agencias de viajes.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceDoubleBedroom3').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label>¿Habitación triple?</label>
                            <mat-radio-group formControlName="tripleBedroom" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('tripleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberTripleBedroom" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberTripleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa público</mat-label>
                                <input formControlName="priceTripleBedroom1" matInput placeholder="Tarifa público"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa público.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceTripleBedroom1').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa corporativo</mat-label>
                                <input formControlName="priceTripleBedroom2" matInput placeholder="Tarifa corporativo"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa corporativo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceTripleBedroom2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa para agencias de viajes</mat-label>
                                <input formControlName="priceTripleBedroom3" matInput
                                    placeholder="Tarifa para agencias de viajes" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa para agencias de viajes.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceTripleBedroom3').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label>¿Habitación cuádruple?</label>
                            <mat-radio-group formControlName="quadrupleBedroom" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('quadrupleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberQuadrupleBedroom" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberQuadrupleBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa público</mat-label>
                                <input formControlName="priceQuadrupleBedroom1" matInput placeholder="Tarifa público"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa público.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceQuadrupleBedroom1').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa corporativo</mat-label>
                                <input formControlName="priceQuadrupleBedroom2" matInput
                                    placeholder="Tarifa corporativo" mask="separator.2" thousandSeparator="."
                                    required />
                                <mat-icon matSuffix matTooltip="Tarifa corporativo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceQuadrupleBedroom2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa para agencias de viajes</mat-label>
                                <input formControlName="priceQuadrupleBedroom3" matInput
                                    placeholder="Tarifa para agencias de viajes" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa para agencias de viajes.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceQuadrupleBedroom3').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label>¿Habitación suite?</label>
                            <mat-radio-group formControlName="suiteBedroom" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('suiteBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberSuiteBedroom" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberSuiteBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa público</mat-label>
                                <input formControlName="priceSuiteBedroom1" matInput placeholder="Tarifa público"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa público.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceSuiteBedroom1').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa corporativo</mat-label>
                                <input formControlName="priceSuiteBedroom2" matInput placeholder="Tarifa corporativo"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa corporativo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceSuiteBedroom2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa para agencias de viajes</mat-label>
                                <input formControlName="priceSuiteBedroom3" matInput
                                    placeholder="Tarifa para agencias de viajes" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa para agencias de viajes.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceSuiteBedroom3').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label>¿Habitación presidencial?</label>
                            <mat-radio-group formControlName="presidentialBedroom" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('presidentialBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de habitaciones</mat-label>
                                <input formControlName="numberPresidentialBedroom" matInput
                                    placeholder="Número de habitaciones" required />
                                <mat-icon matSuffix matTooltip="Número de habitaciones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberPresidentialBedroom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa público</mat-label>
                                <input formControlName="pricePresidentialBedroom1" matInput placeholder="Tarifa público"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa público.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('pricePresidentialBedroom1').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa corporativo</mat-label>
                                <input formControlName="pricePresidentialBedroom2" matInput
                                    placeholder="Tarifa corporativo" mask="separator.2" thousandSeparator="."
                                    required />
                                <mat-icon matSuffix matTooltip="Tarifa corporativo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('pricePresidentialBedroom2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa para agencias de viajes</mat-label>
                                <input formControlName="pricePresidentialBedroom3" matInput
                                    placeholder="Tarifa para agencias de viajes" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa para agencias de viajes.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('pricePresidentialBedroom3').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherBedroom" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Salones para eventos</h3>
                        </div>
                        <div class="col-md-6">
                            <label>¿Tu empresa cuenta con salones para eventos?</label>
                            <mat-radio-group formControlName="eventsCompany" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('eventsCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad de salones</mat-label>
                                <input formControlName="numberEventRooms" matInput placeholder="Cantidad de salones"
                                    required />
                                <mat-icon matSuffix matTooltip="Cantidad de salones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('numberEventRooms').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad de aforo</mat-label>
                                <input formControlName="capacityEventRooms" matInput placeholder="Cantidad de aforo"
                                    required />
                                <mat-icon matSuffix matTooltip="Cantidad de aforo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('capacityEventRooms').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceEventRooms" matInput placeholder="Tarifa"
                                    mask="separator.2" thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('priceEventRooms').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherEventRooms" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios para eventos</h3>
                        </div>
                        <div class="col-md-6">
                            <label>Ayudas audiovisuales (videobeam, pantallas, etc.)</label>
                            <mat-radio-group formControlName="audiovisualAids" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2Form.get('audiovisualAids').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-3">
                            <label>Sonido</label>
                            <mat-radio-group formControlName="sound" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2Form.get('sound').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-3">
                            <label>Micrófono</label>
                            <mat-radio-group formControlName="microphone" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2Form.get('microphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <label>Estación de agua y café</label>
                            <mat-radio-group formControlName="waterCoffee" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2Form.get('waterCoffee').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <label>Catering</label>
                            <mat-radio-group formControlName="Catering" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2Form.get('Catering').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherEventService" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Servicios <br /> Seleccione los servicios que prestas en tu establecimiento:
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="restaurante" class="mr-3" labelPosition="before">
                                            Restaurante</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="piscina" class="mr-3" labelPosition="before">
                                            Piscina</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="aire" class="mr-3" labelPosition="before">Aire
                                            acondicionado</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="television" class="mr-3" labelPosition="before">
                                            Televisón por cable</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="internet" class="mr-3" labelPosition="before">
                                            Acceso a internet</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="banos" class="mr-3" labelPosition="before">Baños
                                            privados</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="salon" class="mr-3" labelPosition="before">Salón
                                            para eventos</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="parqueadero" class="mr-3" labelPosition="before">
                                            Parqueadero privado</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherServiceCompany" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('bookWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y
                                comercialización en línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu
                                    establecimiento a turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura
                                    asociativa?, indica cual o cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="save()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
        <!-- Pasos para gastronomia -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 2 && subCategory.value && ubication.value">
            <mat-step [stepControl]="registerStep1Form">
                <form [formGroup]="registerStep1Form">
                    <ng-template matStepLabel>Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de la empresa</mat-label>
                                <input formControlName="nameCompany" matInput placeholder="Mi empresa" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('nameCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionRnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Ubicación exacta del establecimiento
                            </h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4 class="subtittle">Ubicación del establecimiento en Google Maps</h4>

                        </div>
                        <div class="col-md-8">

                            <mat-form-field appearance="outline">
                                <mat-label>Insertar iframe de ubicación del establecimiento en Google Maps</mat-label>
                                <input formControlName="locationEstablishment" matInput
                                    placeholder="Insertar iframe de ubicación del establecimiento en Google Maps"
                                    required />
                                <mat-hint><a
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">Mira aquí como insertar
                                        el iframe de
                                        ubicación del establecimiento en Google Maps</a> </mat-hint>

                                <mat-icon matSuffix
                                    matTooltip="Mira aquí como insertar el iframe de ubicación del establecimiento en Google Maps.">
                                    <a class="link_video"
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">help_outline</a>
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-4">
                            <label>¿Está formalizado?</label>
                            <mat-radio-group formControlName="optionLegal">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionLegal').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Accesibilidad</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Indicaciones para el acceso</mat-label>
                                <textarea formControlName="access" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep1Form.get('access').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Horarios de atención entre semana</h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day1" labelPosition="before">Lunes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day2" labelPosition="before">Martes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day3" labelPosition="before">Miercoles
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day4" labelPosition="before">Jueves
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day5" labelPosition="before">Viernes
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Week" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Week" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Horarios de atención fines de semanas y festivos
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day6" labelPosition="before">Sabado
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day7" labelPosition="before">Domingo
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day8" labelPosition="before">Festivos
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Weekend" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Weekend" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Estructura empresarial</h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4>Empleados vinculados a la empresa</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación directa</mat-label>
                                <input formControlName="employedDirect" matInput placeholder="Vinculación directa"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación directa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedDirect').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación temporal</mat-label>
                                <input formControlName="employedTemp" matInput placeholder="Vinculación temporal"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación temporal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedTemp').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep2FormFood">
                <form [formGroup]="registerStep2FormFood">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Capacidad</h3>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de total de mesas</mat-label>
                                <input formControlName="numberTableCompany" matInput
                                    placeholder=">Número de total de mesa" required />
                                <mat-icon matSuffix matTooltip=">Número de total de mesa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('numberTableCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Tipos de mesas</h3>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Mesas para (2) personas</mat-label>
                                <input formControlName="numberTable2" matInput placeholder="Mesas para (2) personas"
                                    required />
                                <mat-icon matSuffix matTooltip="Mesas para (2) personas.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('numberTable2').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Mesas para (4) personas</mat-label>
                                <input formControlName="numberTable4" matInput placeholder="Mesas para (4) personas"
                                    required />
                                <mat-icon matSuffix matTooltip="Mesas para (4) personas.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('numberTable4').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Mesas para (6) personas</mat-label>
                                <input formControlName="numberTable6" matInput placeholder="Mesas para (6) personas"
                                    required />
                                <mat-icon matSuffix matTooltip="Mesas para (6) personas.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('numberTable6').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="numberTableOther" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Área total del restaurante (m3)</mat-label>
                                <input formControlName="restaurantArea" matInput
                                    placeholder="Área total del restaurante (m3)" required />
                                <mat-icon matSuffix matTooltip="Área total del restaurante (m3).">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('restaurantArea').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label>¿Cuenta con zona de picnic?</label>
                            <mat-radio-group formControlName="picnicZone" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('picnicZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Capacidad de zona picnic</mat-label>
                                <input formControlName="numberPicnicZone" matInput
                                    placeholder="Capacidad de zona picnic" required />
                                <mat-icon matSuffix matTooltip="Capacidad de zona picnic.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('numberPicnicZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Comidas</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu establecimiento a
                                    turistas y visitantes</mat-label>
                                <textarea formControlName="menu" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep2FormFood.get('menu').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Salones para eventos</h3>
                        </div>
                        <div class="col-md-6">
                            <label>¿Tu empresa cuenta con salones para eventos?</label>
                            <mat-radio-group formControlName="eventsCompany" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('eventsCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad de salones</mat-label>
                                <input formControlName="numberEventRooms" matInput placeholder="Cantidad de salones"
                                    required />
                                <mat-icon matSuffix matTooltip="Cantidad de salones.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('numberEventRooms').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad de aforo</mat-label>
                                <input formControlName="capacityEventRooms" matInput placeholder="Cantidad de aforo"
                                    required />
                                <mat-icon matSuffix matTooltip="Cantidad de aforo.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('capacityEventRooms').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceEventRooms" matInput placeholder="Tarifa" required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('priceEventRooms').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherEventRooms" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios para eventos</h3>
                        </div>
                        <div class="col-md-6">
                            <label>Ayudas audiovisuales (videobeam, pantallas, etc.)</label>
                            <mat-radio-group formControlName="audiovisualAids" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('audiovisualAids').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-3">
                            <label>Sonido</label>
                            <mat-radio-group formControlName="sound" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2FormFood.get('sound').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-3">
                            <label>Micrófono</label>
                            <mat-radio-group formControlName="microphone" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('microphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <label>Estación de agua y café</label>
                            <mat-radio-group formControlName="waterCoffee">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('waterCoffee').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <label>Catering</label>
                            <mat-radio-group formControlName="Catering">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormFood.get('Catering').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherEventService" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Servicios <br /> Seleccione los servicios que prestas en tu establecimiento:
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="restaurante" class="mr-3" labelPosition="before">
                                            Restaurante</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="piscina" class="mr-3" labelPosition="before">
                                            Piscina</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="aire" class="mr-3" labelPosition="before">Aire
                                            acondicionado</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="television" class="mr-3" labelPosition="before">
                                            Televisón por cable</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="internet" class="mr-3" labelPosition="before">
                                            Acceso a internet</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="banos" class="mr-3" labelPosition="before">Baños
                                            privados</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="salon" class="mr-3" labelPosition="before">Salón
                                            para eventos</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="parqueadero" class="mr-3" labelPosition="before">
                                            Parqueadero privado</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherServiceCompany" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('urlWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('bookWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('facebook').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('twitter').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('instagram').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y
                                comercialización en línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu
                                    establecimiento a turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura
                                    asociativa?, indica cual o cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="saveFood()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
        <!-- Pasos para agencias -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 3 && subCategory.value && ubication.value">
            <mat-step [stepControl]="registerStep1FormTour">
                <form [formGroup]="registerStep1FormTour">
                    <ng-template matStepLabel>Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de la empresa</mat-label>
                                <input formControlName="nameCompany" matInput placeholder="Mi empresa" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('nameCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error *ngFor="let error of registerStep1FormTour.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Ubicación exacta del establecimiento
                            </h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4 class="subtittle">Ubicación del establecimiento en Google Maps</h4>

                        </div>
                        <div class="col-md-8">

                            <mat-form-field appearance="outline">
                                <mat-label>Insertar iframe de ubicación del establecimiento en Google Maps</mat-label>
                                <input formControlName="locationEstablishment" matInput
                                    placeholder="Insertar iframe de ubicación del establecimiento en Google Maps"
                                    required />
                                <mat-hint><a
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">Mira aquí como insertar
                                        el iframe de
                                        ubicación del establecimiento en Google Maps</a> </mat-hint>

                                <mat-icon matSuffix
                                    matTooltip="Mira aquí como insertar el iframe de ubicación del establecimiento en Google Maps.">
                                    <a class="link_video"
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">help_outline</a>
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-12">
                            <label>¿La actividad comercial de la agencia es digital ?</label>
                            <mat-radio-group formControlName="digitalAgent">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('digitalAgent').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1FormTour.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Accesibilidad</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Indicaciones para el acceso</mat-label>
                                <textarea formControlName="access" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep1FormTour.get('access').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Horarios de atención entre semana</h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day1" labelPosition="before">Lunes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day2" labelPosition="before">Martes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day3" labelPosition="before">Miercoles
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day4" labelPosition="before">Jueves
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day5" labelPosition="before">Viernes
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Week" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Week" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Horarios de atención fines de semanas y festivos
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day6" labelPosition="before">Sabado
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day7" labelPosition="before">Domingo
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day8" labelPosition="before">Festivos
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Weekend" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Weekend" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Estructura empresarial</h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4>Empleados vinculados a la empresa</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación directa</mat-label>
                                <input formControlName="employedDirect" matInput placeholder="Vinculación directa"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación directa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('employedDirect').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación temporal</mat-label>
                                <input formControlName="employedTemp" matInput placeholder="Vinculación temporal"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación temporal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTour.get('employedTemp').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep2FormTour">
                <form [formGroup]="registerStep2FormTour">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios</h3>
                        </div>
                        <div class="col-md-6">
                            <label>Turismo emisivo internacionales</label>
                            <mat-radio-group formControlName="internationalEmissive" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('internationalEmissive').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>Turismo emisivo nacional</label>
                            <mat-radio-group formControlName="nationalEmissive" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('nationalEmissive').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Teniendo como destino principal Ibagué y el Tolima su
                                agencia de viajes desarrolla</h3>
                        </div>
                        <div class="col-md-3">
                            <label>Turismo receptivo internacional</label>
                            <mat-radio-group formControlName="tolimaInternationalEmissive" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('tolimaInternationalEmissive').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <label>Turismo receptivo nacional</label>
                            <mat-radio-group formControlName="tolimaNationalEmissive">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('tolimaNationalEmissive').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-4">
                            <label>Turismo regional (entre municipios del departamento)</label>
                            <mat-radio-group formControlName="departmentTour">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('departmentTour').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otros</mat-label>
                                <input formControlName="otherTour" matInput placeholder="Otros" />
                                <mat-icon matSuffix matTooltip="Otros.">help_outline </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                ¿Cuales actividades de turismo de aventura opera su empresa?
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="mountain" class="mr-3" labelPosition="before">
                                            Alta montaña</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="canyoning" class="mr-3" labelPosition="before">
                                            Canyoning (escalada en roca)</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="rafting" class="mr-3" labelPosition="before">
                                            Rafting</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="rappel" class="mr-3" labelPosition="before">
                                            Rappel (torrentismo)</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="canopy" class="mr-3" labelPosition="before">
                                            Canopy</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="trekking" class="mr-3" labelPosition="before">
                                            Trekking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="speleology" class="mr-3" labelPosition="before">
                                            Espeleología</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="ride" class="mr-3" labelPosition="before">
                                            Cabalgata</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="mountainBiking" class="mr-3"
                                            labelPosition="before">Ciclomontañismo</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="paragliding" class="mr-3" labelPosition="before">
                                            Parapente</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-6">
                            <label>¿Cuenta con certificación turística?</label>
                            <mat-radio-group formControlName="certificate" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('certificate').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <mat-checkbox formControlName="certificateRafting" class="mr-3"
                                            labelPosition="before">Requisitos para la operación de actividades de
                                            rafting en turismo de aventura. </mat-checkbox>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <mat-checkbox formControlName="certificateRapel" class="mr-3"
                                            labelPosition="before">Requisitos para la operación de actividades de Rapel
                                            en turismo de aventura. </mat-checkbox>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <mat-checkbox formControlName="certificateSpeleology" class="mr-3"
                                            labelPosition="before">Requisitos para la operación de actividades de
                                            espeleología recreativa en turismo de aventura. </mat-checkbox>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <mat-checkbox formControlName="certificateParagliding" class="mr-3"
                                            labelPosition="before">Requisitos para la operación de actividades de
                                            Parapente en turismo de aventura.</mat-checkbox>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <mat-checkbox formControlName="certificateRide" class="mr-3"
                                            labelPosition="before">Requisitos para la operación de actividades de
                                            cabalgata en turismo de aventura. </mat-checkbox>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <mat-checkbox formControlName="certificateCanyoning" class="mr-3"
                                            labelPosition="before">Requisitos para la operación de actividades de
                                            canyoning en turismo de aventura </mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otro</mat-label>
                                <input formControlName="otherCertificate" matInput placeholder="otro" />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con vehículos propios?</label>
                            <mat-radio-group formControlName="transport" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('transport').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de vehículos</mat-label>
                                <input formControlName="numberCar" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('numberCar').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Diligencie la principal oferta de servicios de turismo receptivo Ibagué-
                                    Tolima que maneja su empresa </mat-label>
                                <textarea formControlName="regionalService" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('regionalService').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Maneja paquetes Colombia en los cuales Ibagué y el Tolima se integran con
                                    otros destinos describa</mat-label>
                                <textarea formControlName="nationalService" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTour.get('nationalService').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('urlWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('facebook').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('twitter').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('instagram').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('otherSocialMedia').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y comercialización en
                                línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu establecimiento a
                                    turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura asociativa?, indica cual o
                                    cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="saveTour()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

        <!-- Pasos para transportes -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 4 && subCategory.value && ubication.value">
            <mat-step [stepControl]="registerStep1FormTransport">
                <form [formGroup]="registerStep1FormTransport">
                    <ng-template matStepLabel>Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de la empresa</mat-label>
                                <input formControlName="nameCompany" matInput placeholder="Mi empresa" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('nameCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Ubicación exacta del establecimiento
                            </h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4 class="subtittle">Ubicación del establecimiento en Google Maps</h4>

                        </div>
                        <div class="col-md-8">

                            <mat-form-field appearance="outline">
                                <mat-label>Insertar iframe de ubicación del establecimiento en Google Maps</mat-label>
                                <input formControlName="locationEstablishment" matInput
                                    placeholder="Insertar iframe de ubicación del establecimiento en Google Maps"
                                    required />
                                <mat-hint><a
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">Mira aquí como insertar
                                        el iframe de
                                        ubicación del establecimiento en Google Maps</a> </mat-hint>

                                <mat-icon matSuffix
                                    matTooltip="Mira aquí como insertar el iframe de ubicación del establecimiento en Google Maps.">
                                    <a class="link_video"
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">help_outline</a>
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-12">
                            <label>¿La actividad comercial de la agencia es digital ?</label>
                            <mat-radio-group formControlName="digitalAgent">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('digitalAgent').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Accesibilidad</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Indicaciones para el acceso</mat-label>
                                <textarea formControlName="access" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('access').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Horarios de atención entre semana</h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day1" labelPosition="before">Lunes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day2" labelPosition="before">Martes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day3" labelPosition="before">Miercoles
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day4" labelPosition="before">Jueves
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day5" labelPosition="before">Viernes
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Week" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Week" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Horarios de atención fines de semanas y festivos
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day6" labelPosition="before">Sabado
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day7" labelPosition="before">Domingo
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day8" labelPosition="before">Festivos
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Weekend" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Weekend" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Estructura empresarial</h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4>Empleados vinculados a la empresa</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación directa</mat-label>
                                <input formControlName="employedDirect" matInput placeholder="Vinculación directa"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación directa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('employedDirect').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación temporal</mat-label>
                                <input formControlName="employedTemp" matInput placeholder="Vinculación temporal"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación temporal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('employedTemp').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep2FormTransport">
                <form [formGroup]="registerStep2FormTransport">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios</h3>
                        </div>
                        <div class="col-md-12">
                            <label>¿Cuenta con certificación?</label>
                            <mat-radio-group formControlName="certificate" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('certificate').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <label>Certificación de calidad turística en sostenibilidad. (normas técnicas de turismo
                                sostenible)</label>
                            <mat-radio-group formControlName="certificateQuality" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('certificateQuality').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿Certficación ISO?</label>
                            <mat-radio-group formControlName="certificateISOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('certificateISOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual?</mat-label>
                                <input formControlName="certificateISO" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('certificateISO').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿Certificación en Biseguridad ?</label>
                            <mat-radio-group formControlName="certificateBIOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('certificateBIOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual?</mat-label>
                                <input formControlName="certificateBIO" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('certificateBIO').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otro</mat-label>
                                <input formControlName="otherCertificate" matInput placeholder="otro" />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con vehículos tipo BUS?</label>
                            <mat-radio-group formControlName="transportBUS" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('transportBUS').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de Buses</mat-label>
                                <input formControlName="numberBUS" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('numberBUS').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con vehículos tipo VANS?</label>
                            <mat-radio-group formControlName="transportVANS" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('transportVANS').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de VANS</mat-label>
                                <input formControlName="numberVANS" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('numberVANS').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con vehículos tipo CHIVAS?</label>
                            <mat-radio-group formControlName="transportCHIVAS" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('transportCHIVAS').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de CHIVAS</mat-label>
                                <input formControlName="numberCHIVAS" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormTransport.get('numberCHIVAS').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otro</mat-label>
                                <input formControlName="otherTransport" matInput placeholder="otro" />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('urlWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('facebook').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('twitter').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('instagram').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('otherSocialMedia').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y comercialización en
                                línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu establecimiento a
                                    turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura asociativa?, indica cual o
                                    cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="saveTransport()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

        <!-- Pasos para guias -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 5 && subCategory.value && ubication.value">
            <mat-step [stepControl]="registerStep1FormGuide">
                <form [formGroup]="registerStep1FormGuide">
                    <ng-template matStepLabel>Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Maneja un segundo idioma</mat-label>
                                <input formControlName="languages" matInput placeholder="Ingles, italiano" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('languages').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿Cuenta con certificaciones en idiomas?</label>
                            <mat-radio-group formControlName="certificateLanguagesOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('certificateLanguagesOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cuales?</mat-label>
                                <input formControlName="certificateLanguages" matInput placeholder="Ingles" required />
                                <mat-icon matSuffix>help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('certificateLanguages').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error *ngFor="let error of registerStep1FormGuide.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormTransport.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿Cuenta con tarjeta profesional?</label>
                            <mat-radio-group formControlName="optionNumberCarpro" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('optionNumberCarpro').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>N° tarjeta profesional</mat-label>
                                <input formControlName="numberCardPro" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="N° tarjeta profesional.">help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('numberCardPro').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1FormGuide.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Descripción breve de los servicios prestados, especialidad </mat-label>
                                <textarea formControlName="descriptionService" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep1FormGuide.get('descriptionService').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep2FormGuide">
                <form [formGroup]="registerStep2FormGuide">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormGuide.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios</h3>
                        </div>
                        <div class="col-md-6">
                            <label>¿Cuenta con certificaciones?</label>
                            <mat-radio-group formControlName="certificate" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormGuide.get('certificate').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cuales?</mat-label>
                                <input formControlName="certificatesList" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormGuide.get('certificatesList').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('urlWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('facebook').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('twitter').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('instagram').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('otherSocialMedia').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y comercialización en
                                línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu establecimiento a
                                    turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura asociativa?, indica cual o
                                    cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="saveGuide()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

        <!-- Pasos para organizador de eventos -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 6 && subCategory.value && ubication.value">
            <mat-step [stepControl]="registerStep1Form">
                <form [formGroup]="registerStep1Form">
                    <ng-template matStepLabel>Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de la empresa</mat-label>
                                <input formControlName="nameCompany" matInput placeholder="Mi empresa" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('nameCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionRnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Ubicación exacta del establecimiento
                            </h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4 class="subtittle">Ubicación del establecimiento en Google Maps</h4>

                        </div>
                        <div class="col-md-8">

                            <mat-form-field appearance="outline">
                                <mat-label>Insertar iframe de ubicación del establecimiento en Google Maps</mat-label>
                                <input formControlName="locationEstablishment" matInput
                                    placeholder="Insertar iframe de ubicación del establecimiento en Google Maps"
                                    required />
                                <mat-hint><a
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">Mira aquí como insertar
                                        el iframe de
                                        ubicación del establecimiento en Google Maps</a> </mat-hint>

                                <mat-icon matSuffix
                                    matTooltip="Mira aquí como insertar el iframe de ubicación del establecimiento en Google Maps.">
                                    <a class="link_video"
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">help_outline</a>
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-4">
                            <label>¿Está formalizado?</label>
                            <mat-radio-group formControlName="optionLegal">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionLegal').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Accesibilidad</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Indicaciones para el acceso</mat-label>
                                <textarea formControlName="access" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep1Form.get('access').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Horarios de atención entre semana</h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day1" labelPosition="before">Lunes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day2" labelPosition="before">Martes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day3" labelPosition="before">Miercoles
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day4" labelPosition="before">Jueves
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day5" labelPosition="before">Viernes
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Week" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Week" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Horarios de atención fines de semanas y festivos
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day6" labelPosition="before">Sabado
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day7" labelPosition="before">Domingo
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day8" labelPosition="before">Festivos
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Weekend" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Weekend" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Estructura empresarial</h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4>Empleados vinculados a la empresa</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación directa</mat-label>
                                <input formControlName="employedDirect" matInput placeholder="Vinculación directa"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación directa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedDirect').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación temporal</mat-label>
                                <input formControlName="employedTemp" matInput placeholder="Vinculación temporal"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación temporal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedTemp').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep2FormEvents">
                <form [formGroup]="registerStep2FormEvents">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Certificaciones</h3>
                        </div>
                        <div class="col-md-12">
                            <label>¿Cuenta con certificación?</label>
                            <mat-radio-group formControlName="certificate" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('certificate').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <label>Certificación de calidad turística en sostenibilidad. (normas técnicas de turismo
                                sostenible)</label>
                            <mat-radio-group formControlName="certificateQuality" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('certificateQuality').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿Certficación ISO?</label>
                            <mat-radio-group formControlName="certificateISOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('certificateISOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual?</mat-label>
                                <input formControlName="certificateISO" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('certificateISO').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿Certificación en Biseguridad ?</label>
                            <mat-radio-group formControlName="certificateBIOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('certificateBIOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual?</mat-label>
                                <input formControlName="certificateBIO" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('certificateBIO').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otro</mat-label>
                                <input formControlName="otherCertificate" matInput placeholder="otro" />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Servicios</h3>
                        </div>
                        <div class="col-md-6">
                            <label>¿Auditorio?</label>
                            <mat-radio-group formControlName="audit" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2FormEvents.get('audit').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad</mat-label>
                                <input formControlName="numberAudit" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberAudit').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Aforo</mat-label>
                                <input formControlName="numberAfor" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberAfor').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceAudit" matInput placeholder="Tarifa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('priceAudit').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <label>¿Aula?</label>
                            <mat-radio-group formControlName="room" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2FormEvents.get('room').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad</mat-label>
                                <input formControlName="numberRoom" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberRoom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Aforo</mat-label>
                                <input formControlName="numberAforRoom" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberAforRoom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceRoom" matInput placeholder="Tarifa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('priceRoom').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <label>¿En U?</label>
                            <mat-radio-group formControlName="roomU" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep2FormEvents.get('roomU').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad</mat-label>
                                <input formControlName="numberRoomU" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberRoomU').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Aforo</mat-label>
                                <input formControlName="numberAforRoomU" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberAforRoomU').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceRoomU" matInput placeholder="Tarifa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('priceRoomU').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <label>¿Imperial?</label>
                            <mat-radio-group formControlName="imperial" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('imperial').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad</mat-label>
                                <input formControlName="numberImperial" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberImperial').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Aforo</mat-label>
                                <input formControlName="numberAforImperial" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberAforImperial').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceImperial" matInput placeholder="Tarifa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('priceImperial').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <label>¿Coctel?</label>
                            <mat-radio-group formControlName="coctel" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('coctel').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cantidad</mat-label>
                                <input formControlName="numberCoctel" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberCoctel').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Aforo</mat-label>
                                <input formControlName="numberAforCoctel" matInput placeholder="Cantidad" required />
                                <mat-icon matSuffix matTooltip="Cantidad.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('numberAforCoctel').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tarifa</mat-label>
                                <input formControlName="priceCoctel" matInput placeholder="Tarifa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Tarifa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormEvents.get('priceCoctel').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otro</mat-label>
                                <input formControlName="otherRoom" matInput placeholder="otro" />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('bookWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y
                                comercialización en línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu
                                    establecimiento a turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura
                                    asociativa?, indica cual o cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="saveEvent()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

        <!-- Pasos para atractivos turisticos -->
        <mat-horizontal-stepper linear #stepper @fade
            *ngIf="category.value === 7 && subCategory.value && ubication.value">
            <mat-step [formGroup]="registerStep1Form">
                <form [formGroup]="registerStep1Form">
                    <ng-template matStepLabel>Contacto</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <h3 class="subtittle subtittle_">Registro fotográfico</h3>
                        </div>
                        <div class="row">
                            <!-- logotipo -->
                            <div class="col-md-12 ml-3">
                                <label>Subir logo del establecimiento:</label>
                            </div>
                            <div class="col-md-12 ml-3">
                                <div class="custom-input-file" [ngClass]="{
                                          selectedColor: logo !== '' || logo !== undefined,
                                          unselectedColor: logo === '' || logo === undefined
                                        }">
                                    <input title="Logotipo" type="file" class="input-file" [(ngModel)]="logo"
                                        [ngModelOptions]="{ standalone: true }"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="cambioArchivo($event, 'logotipo')" /> +
                                </div>
                            </div>
                        </div>
                        <!-- fotos -->
                        <div class="row mb-3">
                            <div class="col-md-12 ml-3">
                                <label>Subir fotos del establecimiento:</label>
                            </div>
                            <div class="d-flex justify-content-around ml-3">
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto1 !== '' || foto1 !== undefined,
                                                    unselectedColor: foto1 === '' || foto1 === undefined
                                                  }">
                                        <input title="Subir foto 1" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto1"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto1')" /> +
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">

                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto2 !== '' || foto2 !== undefined,
                                                    unselectedColor: foto2 === '' || foto2 === undefined
                                                }">
                                        <input title="Subir foto 2" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto2"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto2')" /> +
                                    </div>

                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="custom-input-file" [ngClass]="{
                                                    selectedColor: foto3 !== '' || foto3 !== undefined,
                                                    unselectedColor: foto3 === '' || foto3 === undefined
                                                  }">
                                        <input title="Subir foto 3" type="file" class="input-file"
                                            accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="foto3"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="cambioArchivo($event, 'foto3')" /> +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de la empresa</mat-label>
                                <input formControlName="nameCompany" matInput placeholder="Mi empresa" required />
                                <mat-icon matSuffix matTooltip="Nombre de la empresa a registrar.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('nameCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>¿Cuenta con RNT?</label>
                            <mat-radio-group formControlName="optionRnt" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionRnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>N° RNT</mat-label>
                                <input formControlName="rnt" matInput placeholder="123456789" required />
                                <mat-icon matSuffix matTooltip="Registro nacional de turismo de la empresa.">
                                    help_outline</mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('rnt').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label>Ubicado en Zona:</label>
                            <mat-radio-group formControlName="optionZone">
                                <mat-radio-button value="rural" class="m-3">
                                    Rural
                                </mat-radio-button>
                                <mat-radio-button value="urbana" class="m-3">
                                    Urbana
                                </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionZone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label> Dirección del establecimiento</mat-label>
                                <input formControlName="addressCompany" matInput placeholder="123456789" required />
                                <mat-icon matSuffix
                                    matTooltip="Dirección donde se encuentra ubicado el establecimiento.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Ubicación exacta del establecimiento
                            </h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4 class="subtittle">Ubicación del establecimiento en Google Maps</h4>

                        </div>
                        <div class="col-md-8">

                            <mat-form-field appearance="outline">
                                <mat-label>Insertar iframe de ubicación del establecimiento en Google Maps</mat-label>
                                <input formControlName="locationEstablishment" matInput
                                    placeholder="Insertar iframe de ubicación del establecimiento en Google Maps"
                                    required />
                                <mat-hint><a
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">Mira aquí como insertar
                                        el iframe de
                                        ubicación del establecimiento en Google Maps</a> </mat-hint>

                                <mat-icon matSuffix
                                    matTooltip="Mira aquí como insertar el iframe de ubicación del establecimiento en Google Maps.">
                                    <a class="link_video"
                                        href="https://drive.google.com/file/d/1uGz5v8k5TA2BHot-NXEL_8zRuhMpvyLu/view"
                                        target="_blank">help_outline</a>
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Datos generales</h3>
                        </div>
                        <div class="col-md-4">
                            <label>¿Está formalizado?</label>
                            <mat-radio-group formControlName="optionLegal">
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep1Form.get('optionLegal').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Dirección Correspondencia</mat-label>
                                <input formControlName="addressCorrespondence" matInput
                                    placeholder="Dirección Correspondencia" required />
                                <mat-icon matSuffix matTooltip="Dirección Correspondencia.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('addressCorrespondence').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>NIT / Identificación</mat-label>
                                <input formControlName="idPerson" matInput placeholder="NIT / Identificación"
                                    required />
                                <mat-icon matSuffix matTooltip="NIT / Identificación.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('idPerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del representante legal</mat-label>
                                <input formControlName="namePerson" matInput
                                    placeholder="Nombre del representante legal" required />
                                <mat-icon matSuffix matTooltip="Nombre del representante legal.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('namePerson').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto</mat-label>
                                <input formControlName="cellphone" matInput placeholder="N° de contacto" required />
                                <mat-icon matSuffix matTooltip="N° de contacto.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('cellphone').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de establecimiento</mat-label>
                                <input formControlName="email" matInput placeholder="Email de establecimiento"
                                    required />
                                <mat-icon matSuffix matTooltip="Email de establecimiento.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep1Form.get('email').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>N° de contacto comercial</mat-label>
                                <input formControlName="cellphoneCompany" matInput
                                    placeholder="N° de contacto comercial" required />
                                <mat-icon matSuffix matTooltip="N° de contacto comercial.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('cellphoneCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Accesibilidad</h3>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Indicaciones para el acceso</mat-label>
                                <textarea formControlName="access" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep1Form.get('access').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Horarios de atención entre semana</h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day1" labelPosition="before">Lunes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day2" labelPosition="before">Martes
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day3" labelPosition="before">Miercoles
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day4" labelPosition="before">Jueves
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day5" labelPosition="before">Viernes
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Week" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Week" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">
                                Horarios de atención fines de semanas y festivos
                            </h3>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto">
                                <mat-checkbox class="mr-3" formControlName="day6" labelPosition="before">Sabado
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day7" labelPosition="before">Domingo
                                </mat-checkbox>
                                <mat-checkbox class="mr-3" formControlName="day8" labelPosition="before">Festivos
                                </mat-checkbox>
                            </section>
                            <div class="row">
                                <div class="col-md-6">
                                    <timepicker formControlName="time1Weekend" class="m-auto"></timepicker>
                                </div>
                                <div class="col-md-6">
                                    <timepicker formControlName="time2Weekend" class="m-auto"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Estructura empresarial</h3>
                        </div>
                        <div class="col-md-4 m-auto">
                            <h4>Empleados vinculados a la empresa</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación directa</mat-label>
                                <input formControlName="employedDirect" matInput placeholder="Vinculación directa"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación directa.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedDirect').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Vinculación temporal</mat-label>
                                <input formControlName="employedTemp" matInput placeholder="Vinculación temporal"
                                    required />
                                <mat-icon matSuffix matTooltip="Vinculación temporal.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep1Form.get('employedTemp').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="next_button" mat-button matStepperNext>
                            Pasar a ventas
                            <div class="number_button">
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [formGroup]="registerStep2FormAtractive">
                <form [formGroup]="registerStep2FormAtractive">
                    <ng-template matStepLabel>Ventas</ng-template>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual es el promedio de ventas mensuales de tu empresa?
                                </mat-label>
                                <input formControlName="priceAVGCompany" matInput
                                    placeholder="Promedio de ventas mensuales de tu empresa" mask="separator.2"
                                    thousandSeparator="." required />
                                <mat-icon matSuffix matTooltip="Promedio de ventas mensuales de tu empresa.">
                                    help_outline</mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('priceAVGCompany').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <hr />
                        </div>
                        <div class="col-12">
                            <h3 class="subtittle subtittle_">Certificaciones</h3>
                        </div>
                        <div class="col-md-12">
                            <label>¿Cuenta con certificación?</label>
                            <mat-radio-group formControlName="certificate" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('certificate').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-12">
                            <label>Certificación de calidad turística en sostenibilidad. (normas técnicas de turismo
                                sostenible)</label>
                            <mat-radio-group formControlName="certificateQuality" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('certificateQuality').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿Certficación ISO?</label>
                            <mat-radio-group formControlName="certificateISOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('certificateISOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual?</mat-label>
                                <input formControlName="certificateISO" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('certificateISO').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿Certificación en Biseguridad ?</label>
                            <mat-radio-group formControlName="certificateBIOption" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('certificateBIOption').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>¿Cual?</mat-label>
                                <input formControlName="certificateBIO" matInput placeholder="otro" required />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                                <mat-error
                                    *ngFor="let error of registerStep2FormAtractive.get('certificateBIO').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Otro</mat-label>
                                <input formControlName="otherCertificate" matInput placeholder="otro" />
                                <mat-icon matSuffix matTooltip="otro.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a contacto
                                <div class="number_button_2">
                                    <div>
                                        <span>1</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="next_button" mat-button matStepperNext>
                                Pasar a mercadeo
                                <div class="number_button">
                                    <div>
                                        <span>3</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="registerStep3Form">
                <form [formGroup]="registerStep3Form">
                    <ng-template matStepLabel>Mercadeo</ng-template>
                    <div class="row">
                        <div class="col-md-6">
                            <label>¿Tu establecimiento cuenta con un sitio web?</label>
                            <mat-radio-group formControlName="webSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Enlace</mat-label>
                                <input formControlName="urlWebSite" matInput placeholder="https//mipagina.com"
                                    required />
                                <mat-icon matSuffix matTooltip="https//mipagina.com.">help_outline
                                </mat-icon>
                                <mat-error *ngFor="let error of registerStep3Form.get('webSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label>¿El sitio web permite realizar pagos en linea?</label>
                            <mat-radio-group formControlName="payWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('payWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-md-6">
                            <label>¿La empresa cuenta con software de reservas?</label>
                            <mat-radio-group formControlName="bookWebSite" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('bookWebSite').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Facebook</mat-label>
                                <input formControlName="facebook" matInput placeholder="Link Facebook" />
                                <mat-icon matSuffix matTooltip="Link Facebook.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Twitter</mat-label>
                                <input formControlName="twitter" matInput placeholder="Link Twitter" />
                                <mat-icon matSuffix matTooltip="Link Twitter.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Instagram</mat-label>
                                <input formControlName="instagram" matInput placeholder="Link Instagram" />
                                <mat-icon matSuffix matTooltip="Link Instagram.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Link Otra red social</mat-label>
                                <input formControlName="otherSocialMedia" matInput placeholder="Link Otra red social" />
                                <mat-icon matSuffix matTooltip="Link Otra red social.">help_outline
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label>¿La empresa tiene presencia en portales de promoción y
                                comercialización en línea?</label>
                            <mat-radio-group formControlName="marketing" required>
                                <mat-radio-button value="si" class="m-3"> Si </mat-radio-button>
                                <mat-radio-button value="no" class="m-3"> No </mat-radio-button>
                                <mat-error *ngFor="let error of registerStep3Form.get('marketing').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 d-flex">
                            <section class="mt-auto mb-auto ml-auto container">
                                <div class="row">
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tripadvisor" class="mr-3" labelPosition="before">
                                            Tripadvisor</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="airbnb" class="mr-3" labelPosition="before">
                                            Airbnb</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="booking" class="mr-3" labelPosition="before">
                                            Booking</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="tolimaTravel" class="mr-3"
                                            labelPosition="before">Tolima Travel</mat-checkbox>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <mat-checkbox formControlName="atrapalo" class="mr-3" labelPosition="before">
                                            Atrapalo</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mencione las razones por las cuales se debe recomendar tu
                                    establecimiento a turistas y visitantes</mat-label>
                                <textarea formControlName="recomendations" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error
                                    *ngFor="let error of registerStep3Form.get('recomendations').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>¿El establecimiento pertenece a un gremio o figura
                                    asociativa?, indica cual o cuales</mat-label>
                                <textarea formControlName="guild" matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
                                    required></textarea>
                                <mat-error *ngFor="let error of registerStep3Form.get('guild').errors | keyvalue">
                                    <div [ngSwitch]="error.key">
                                        <ng-template [ngSwitchCase]="'required'">
                                            Requerido
                                        </ng-template>
                                        <ng-template ngSwitchDefault>
                                            {{error.key}}
                                        </ng-template>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 text-right next_">
                            <button class="next_button" mat-button matStepperPrevious>
                                Volver a ventas
                                <div class="number_button_2">
                                    <div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 text-left next_">
                            <button class="back_button" [disabled]="isLoading"  mat-button matStepperNext (click)="saveAtractive()">
                                <i
                                [ngClass]="{ 'fa fa-spin fa-spinner': isLoading }"
                              ></i>
                                {{buttonTitle}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>